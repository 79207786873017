import { useEffect, useState } from "react";

const Timer = (props) => {
  const { seconds, callback } = props;

  const [time, setTime] = useState(seconds);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTime((pre) => {
        if (pre > 0) {
          return pre - 1;
        }
        return 0;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      setTime(seconds);
    }
  }, [seconds]);

  useEffect(() => {
    if (time <= 0) {
      callback();
    }
  }, [time]);

  const displayTime = (sec) => {
    let minutes = parseInt(sec / 60);
    let remSec = sec % 60;

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (remSec < 10) {
      remSec = "0" + remSec;
    }

    return `${minutes} : ${remSec}`;
  };

  return <>{displayTime(time)}</>;
};

export default Timer;
