import axios from "axios";

import { errorHandler, commonRes } from "./ErrorHandler";

export const getManufacturers = () => {
  const access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    const headers = {
      authorization: "Bearer " + localStorage.getItem("access_token"),
    };
    return axios
      .get(`${backend_url}/api/user/qa_manufacturers`, {
        headers: headers,
      })
      .then((res) => commonRes(false, res.data))
      .catch((err) => errorHandler(err, getManufacturers));
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};
