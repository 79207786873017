import React, { useEffect, useState } from "react";

import Header from "../../Components/common/Header";
import Loader from "../../Components/common/Loader";
import { Form, Divider, Radio, DatePicker, Select, message, Alert } from "antd";
import {
  getAuditByDate,
  getImageDetails,
  updateLabels,
  getProductDetails,
} from "../../API/ViewOneImage";
import { Logout, ClearClaims } from "../../API/Logout";
import { computeDate } from "../../services";
import "./ViewOneImage.css";
import "antd/dist/antd.css";
import Main from "../sketch/Main";
import VOI from "../sketch/ViewOneImg";
import ReactImageAnnotate from "../../libraries/react-image-annotate";

const ViewOneImage = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    audit_id: "",
    image_index: "",
  });
  const [cvdata, setCvData] = useState([]);
  const [emaildata, setEmailData] = useState([]);
  const [storedata, setStoreData] = useState([]);
  const [baytypedata, setBayTypeData] = useState([]);
  const [baynumberdata, setBayNumberData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [audit, setAudit] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [store, setStore] = React.useState("");
  const [baytype, setBayType] = React.useState("");
  const [baynumber, setBayNumber] = React.useState("");

  const initialImgData = {
    image_url: "",
    user: "",
    audit_id: "",
    store_name: "",
    client_name: "",
    image_index: 0,
    date: "",
    bay_number: "",
    bay_type: "",
    finalized_shelf_polygons: [],
    finalized_classification_bboxes: [],
    finalized_classification_labels: [],
    last_verified_by: [],
    image_status: "",
  };

  const [imgData, setImgData] = useState({ ...initialImgData });
  const [annotation, setaAnnotation] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [labels, setLabels] = useState([]);
  useEffect(() => {}, []);

  async function onChangeDate(date, dateString) {
    setLoading(true);
    setCvData([]);
    setEmailData([]);
    setStoreData([]);
    setBayTypeData([]);
    setBayNumberData([]);
    setStore("");
    setBayType("");
    setBayNumber("");
    getAuditByDate(dateString)
      .then((res) => {
        if (res.error) {
          if (res.errorCode && res.errorCode === "NO_ACCESS_TOKEN") {
            Logout();
          }
        } else {
          if (res.data && Object.keys(res.data).length > 0) {
            var arrData = [];
            var arrEmail = [];
            var arrTemp = [];
            var arrStore = [];
            var arrBayType = [];
            var arrBayNumber = [];
            for (var i = 0; i < Object.keys(res.data).length; i++) {
              if (arrTemp.indexOf(res.data[i].user) === -1)
                arrEmail.push({
                  label: res.data[i].user,
                  value: res.data[i].user,
                });
              arrTemp.push(res.data[i].user);
              arrStore.push({
                label:
                  res.data[i].store_name +
                  " (" +
                  computeDate(
                    res.data[i].dateSubmission
                      ? res.data[i].dateSubmission
                      : res.data[i].date
                  ) +
                  ")",
                value: res.data[i]._id + "@@@" + res.data[i].store,
              });
            }
            setCvData(res.data);
            setEmailData(arrEmail);
            setStoreData(arrStore);
            setBayTypeData([]);
            setBayNumberData([]);
          } else {
            setCvData([]);
            setEmailData([]);
            setStoreData([]);
            setBayTypeData([]);
            setBayNumberData([]);
          }
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }

  async function onChangeStore(value) {
    setLoading(true);
    setBayTypeData([]);
    setBayType("");
    setBayNumber("");
    var arrBayType = [];
    var arrTemp = [];
    setAudit(value.split("@@@")[0]);
    setState({
      ...state,
      audit_id: value.split("@@@")[0],
    });
    for (var i = 0; i < Object.keys(cvdata).length; i++) {
      //console.log(cvdata[i]);
      if (cvdata[i]._id === value.split("@@@")[0]) {
        for (var j = 0; j < cvdata[i].processed_bay_type.length; j++) {
          if (cvdata[i].store === value.split("@@@")[1]) {
            if (arrTemp.indexOf(cvdata[i].processed_bay_type[j]) === -1)
              arrBayType.push({
                label: cvdata[i].processed_bay_type[j],
                value: cvdata[i].processed_bay_type[j],
              });
            arrTemp.push(cvdata[i].processed_bay_type[j]);
          }
        }
      }
    }
    setStore(value);
    setBayTypeData(arrBayType);
    setLoading(false);
  }

  async function onChangeBayType(value) {
    setLoading(true);
    setBayNumberData([]);
    setBayNumber("");
    var arrBayNumber = [];
    for (var i = 0; i < Object.keys(cvdata).length; i++) {
      if (cvdata[i]._id === audit) {
        for (var j = 0; j < cvdata[i].processed_bay_type.length; j++) {
          if (
            cvdata[i].store === store.split("@@@")[1] &&
            cvdata[i].processed_bay_type[j] === value
          ) {
            arrBayNumber.push({
              label: cvdata[i].processed_bay_num[j],
              value: j,
            });
          }
        }
      }
    }
    setBayType(value);
    setBayNumberData(arrBayNumber);
    setLoading(false);
  }

  async function onChangeBayNumber(value) {
    setState({
      ...state,
      image_index: value,
    });
    setBayNumber(value);
  }

  const randomColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6) + "50";
  };

  function getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const handleGetImageDetails = async (e) => {
    try {
      if (audit !== "" && value !== "" && baynumber !== "") {
        localStorage.setItem("current_index", "");
        var data = {
          audit_id: audit,
          image_index: baynumber,
        };

        getImageDetails(data)
          .then((res) => {
            let img = new Image();
            let finalData = [];
            setImgData({ ...res.data });
            console.log("---------------");
            img.src = res.data.image_url;
            img.onload = function() {
              setImageDimensions({
                width: this.width,
                height: this.height,
              });

              //console.log(res.data.finalized_classification_bboxes, "bboxes");
              setLabels([...res.data.finalized_classification_labels]);

              for (let [
                index,
                polygon,
              ] of res.data.finalized_classification_bboxes.entries()) {
                let newPoints = [];
                for (let [key, points] of polygon.entries()) {
                  if (key === 0 || key % 2 === 0) {
                    newPoints.push(points * this.height);
                  } else {
                    newPoints.push(points * this.width);
                  }
                }

                let shape = {
                  id: index,
                  isClosed: true,
                  color: randomColor(),
                  points: [
                    newPoints[1],
                    newPoints[0],
                    newPoints[3],
                    newPoints[2],
                  ],
                  hover: false,
                };

                finalData.push(shape);
              }
              setShapes([...finalData]);

              const annotations = [];
              for (
                let i = 0;
                i < res.data.finalized_classification_bboxes.length;
                i++
              ) {
                if (res.data.finalized_classification_bboxes[i].length) {
                  const item = {
                    cls: res.data.finalized_classification_labels[i],
                    h:
                      res.data.finalized_classification_bboxes[i][2] -
                      res.data.finalized_classification_bboxes[i][0],
                    w:
                      res.data.finalized_classification_bboxes[i][3] -
                      res.data.finalized_classification_bboxes[i][1],
                    x: res.data.finalized_classification_bboxes[i][1],
                    y: res.data.finalized_classification_bboxes[i][0],
                    color: getRandomColor(),
                    id: Math.floor(Math.random() * 1e16).toString(),
                    type: "box",
                    shelf_number: 0,
                    last_verified_by: res.data.last_verified_by[i],
                  };
                  annotations.push(item);
                }
              }
              setaAnnotation(annotations);
            };
          })
          .catch((error) => {
            console.log(error.toString());
          });
      } else alert("Please select data.");
    } catch (error) {
      console.log(error.toString());
    }
  };

  const handleGetImageDetails_1 = async (e) => {
    try {
      if (state.audit_id !== "" && state.image_index !== "") {
        localStorage.setItem("current_index", "");
        var data = {
          audit_id: state.audit_id,
          image_index: state.image_index,
        };
        //console.log(data);
        getImageDetails(data)
          .then((res) => {
            let img = new Image();
            let finalData = [];
            setImgData({ ...res.data });
            console.log("---------------");
            img.src = res.data.image_url;
            img.onload = function() {
              setImageDimensions({
                width: this.width,
                height: this.height,
              });

              console.log(res.data.finalized_classification_bboxes, "bboxes");
              setLabels([...res.data.finalized_classification_labels]);

              for (let [
                index,
                polygon,
              ] of res.data.finalized_classification_bboxes.entries()) {
                let newPoints = [];
                for (let [key, points] of polygon.entries()) {
                  if (key === 0 || key % 2 === 0) {
                    newPoints.push(points * this.height);
                  } else {
                    newPoints.push(points * this.width);
                  }
                }

                let shape = {
                  id: index,
                  isClosed: true,
                  color: randomColor(),
                  points: [
                    newPoints[1],
                    newPoints[0],
                    newPoints[3],
                    newPoints[2],
                  ],
                  hover: false,
                };

                finalData.push(shape);
              }
              setShapes([...finalData]);

              const annotations = [];
              for (
                let i = 0;
                i < res.data.finalized_classification_bboxes.length;
                i++
              ) {
                if (res.data.finalized_classification_bboxes[i].length) {
                  const item = {
                    cls: res.data.finalized_classification_labels[i],
                    h:
                      res.data.finalized_classification_bboxes[i][2] -
                      res.data.finalized_classification_bboxes[i][0],
                    w:
                      res.data.finalized_classification_bboxes[i][3] -
                      res.data.finalized_classification_bboxes[i][1],
                    x: res.data.finalized_classification_bboxes[i][1],
                    y: res.data.finalized_classification_bboxes[i][0],
                    color: getRandomColor(),
                    id: Math.floor(Math.random() * 1e16).toString(),
                    type: "box",
                    shelf_number: 0,
                    last_verified_by: res.data.last_verified_by[i],
                  };
                  annotations.push(item);
                }
              }
              setaAnnotation(annotations);
            };
          })
          .catch((error) => {
            console.log(error.toString());
          });
      } else alert("Please select data.");
    } catch (error) {
      console.log(error.toString());
    }
  };

  const showProductDetails = async (annotation) => {
    document.getElementById("base_image").innerHTML = "";
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    var data = {
      product_id: localStorage.getItem("current_tag"),
    };
    getProductDetails(data)
      .then((result) => {
        if (result && result.data) {
          //seBaseImg(result.data[0].base_picture_url);
          // result.data = result.data.slice(0, 3);
          var content_base_image = "";
          for (var i = 0; i < Object.keys(result.data).length; i++) {
            if (i < 3)
              content_base_image =
                content_base_image +
                "<div className='row' style='padding: 10px;justify-content: center;text-align: center'><img src='" +
                result.data[i] +
                "' height='150' }}></img></div>";
          }
          document.getElementById("base_image").innerHTML = content_base_image;
        }
        //document.getElementById("div_500").focus();
        //document.getElementById("div_500").click();
      })
      .catch((error) => {
        //document.getElementById("div_500").focus();
        //document.getElementById("div_500").click();
        console.log(error.toString());
      });
  };

  const SetAnnotator = (props) => {
    for (var i = 0; i < annotation.length; i++) {
      annotation[i].id = i;
    }
    console.log(annotation);
    const imageArr = [
      {
        src: props.image,
        regions: annotation,
      },
    ];
    return (
      <ReactImageAnnotate
        //regionClsList={verification.auditData.labels.sort()}
        regionTagList={imgData.verification_labels.sort()}
        //onExit={refreshLabels}
        onSave={saveAnnotation}
        onExit={saveAnnotations}
        //onSave={saveAnnotation}
        //onPrevImage={getPrevious}
        // onNextImage={skipCurrent}
        images={imageArr}
        onOpen={showProductDetails}
        onChange={showProductDetails}
        hidePrev={true}
        hideNext={true}
        hideSettings={true}
        hideSave={true}
        hideFullScreen={true}
        enabledTools={[]}
      />
    );
  };

  const saveAnnotation = (data) => {
    localStorage.setItem("annotation", JSON.stringify(data.images[0].regions));
  };

  const saveAnnotations = (data) => {
    let arrRegions = [];
    let bbox_index = [];
    if (data.images[0].regions) {
      for (var i = 0; i < data.images[0].regions.length; i++) {
        arrRegions.push(data.images[0].regions[i].cls);
      }
      var existing = localStorage.getItem("current_index");
      existing = existing ? existing.split(",") : [];
      let uniqueArr = [...new Set(existing)];
      bbox_index = uniqueArr;
      for (var j = 0; j < bbox_index.length; j++) {
        bbox_index[j] = parseInt(bbox_index[j]);
      }
    }
    const body = {
      //index: verification.auditData.image_index,
      image_index: parseInt(state.image_index),
      audit_id: state.audit_id,
      //finalized_classification_label: arrRegions[bbox_index].cls,
      finalized_classification_labels: arrRegions,
      changed_bbox_indexes: bbox_index,
    };

    updateLabels(body)
      .then((res) => {
        if (res.error) {
          if (res.errorCode && res.errorCode === "NO_ACCESS_TOKEN") {
            Logout();
          }
        } else {
          message.success("Done");
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  return (
    <div>
      {loading && <Loader />}
      <Header />
      <div className="row" style={{ padding: 20 }}>
        <div className="col-sm-9">
          <div className="row" style={{ fontSize: 14 }}>
            <div className="col-sm-8" style={{ padding: 10 }}>
              <div className="row" style={{ padding: 10 }}>
                <strong className="col-sm-2" style={{ marginTop: 7 }}>
                  Date:{" "}
                </strong>
                <div className="col-sm-10">
                  <DatePicker onChange={onChangeDate} />
                </div>
              </div>

              <div className="row" style={{ padding: 10 }}>
                <strong className="col-sm-2" style={{ marginTop: 7 }}>
                  Store:{" "}
                </strong>
                <div className="col-sm-10">
                  <Select
                    //showSearch
                    style={{ width: "100%" }}
                    placeholder="Select a store"
                    optionFilterProp="children"
                    onChange={onChangeStore}
                    options={storedata}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={store}
                  ></Select>
                </div>
              </div>
              <div className="row" style={{ padding: 10 }}>
                <strong className="col-sm-2" style={{ marginTop: 7 }}>
                  Bay type:{" "}
                </strong>
                <div className="col-sm-10">
                  <Select
                    //showSearch
                    style={{ width: "100%" }}
                    placeholder="Select a bay type"
                    optionFilterProp="children"
                    onChange={onChangeBayType}
                    options={baytypedata}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={baytype}
                  ></Select>
                </div>
              </div>
              <div className="row" style={{ padding: 10 }}>
                <strong className="col-sm-2" style={{ marginTop: 7 }}>
                  Bay number:{" "}
                </strong>
                <div className="col-sm-10">
                  <Select
                    //showSearch
                    style={{ width: "100%" }}
                    placeholder="Select a bay number"
                    optionFilterProp="children"
                    onChange={onChangeBayNumber}
                    options={baynumberdata}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={baynumber}
                  ></Select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <button
                style={{
                  fontSize: 14,
                  backgroundColor: "#56B356",
                  borderColor: "#56B356",
                  marginTop: 20,
                }}
                className="btn btn-primary"
                onClick={handleGetImageDetails}
              >
                Submit
              </button>
            </div>
            <div className="col-sm-6"></div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="form-group">
            <label htmlFor="barcode">Audit ID:</label>
            <div className="input-group mb-3">
              <input
                onBlur={(e) =>
                  setState({
                    ...state,
                    audit_id: e.target.value,
                  })
                }
                type="text"
                className="form-control"
                placeholder="Enter Audit ID"
                style={{ fontSize: 14 }}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="barcode">Image Index:</label>
            <div className="input-group mb-3">
              <input
                onBlur={(e) =>
                  setState({
                    ...state,
                    image_index: e.target.value,
                  })
                }
                type="text"
                className="form-control"
                placeholder="Enter Image Index"
                style={{ fontSize: 14 }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-8" style={{ textAlign: "right" }}>
              <button
                style={{
                  fontSize: 14,
                  backgroundColor: "#56B356",
                  borderColor: "#56B356",
                  marginTop: 10,
                }}
                className="btn btn-primary"
                onClick={handleGetImageDetails_1}
              >
                Submit
              </button>

              {/*<button
                style={{
                  backgroundColor: "#56B356",
                  borderColor: "#56B356",
                  marginLeft: 10,
                }}
                className="btn btn-primary"
                onClick={refreshLabels}
              >
                Refresh Labels
              </button>*/}
            </div>
          </div>
          <div className="form-group" style={{ marginTop: 20 }}>
            <Alert
              message={"Image status: " + imgData.image_status}
              type="info"
              showIcon
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="row" style={{ marginTop: 0 }}>
        {imgData.image_url && (
          <>
            <div
              className="col-sm-9"
              style={{
                marginTop: 20,
                padding: 10,
                paddingLeft: 60,
                height: 1200,
              }}
            >
              {/*<VOI
              img_url={imgData.image_url}
              shapes={shapes}
              setShapes={setShapes}
              labels={labels}
              setLabels={setLabels}
            />{" "}*/}
              <SetAnnotator
                image={imgData.image_url}
                annot={imgData.annotations}
              />
            </div>
            <div
              className="col-sm-3"
              style={{ height: 600, marginTop: 70 }}
              id="base_image"
            ></div>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewOneImage;
