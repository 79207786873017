export * from "./user.service";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const computeDate = (date) => {
  const dateParts = date.includes("T") ? date.split("T") : date.split(" ");
  let year = parseInt(dateParts[0].split("-")[0]);
  let month = dateParts[0].split("-")[1];
  let dt = dateParts[0].split("-")[2];
  let hour = dateParts[1].split(":")[0];
  let minute = parseInt(dateParts[1].split(":")[1]);
  let ampm = parseInt(hour) >= 12 ? "PM" : "AM";
  hour = (parseInt(hour) % 12).toString();
  hour = hour !== "0" ? hour : "12"; // the hour '0' should be '12'
  const minutes = minute < 10 ? "0" + minute.toString() : minute.toString();
  const strTime = hour + ":" + minutes + " " + ampm;
  let endDate = "";
  if (parseInt(dt) < 10 && dt.length === 1) {
    dt = "0" + dt;
  }
  if (parseInt(month) < 10 && month.length === 1) {
    month = "0" + month;
  }
  endDate =
    dt +
    " " +
    monthNames[parseInt(date.split("T")[0].split("-")[1]) - 1] +
    ", " +
    year +
    " " +
    strTime;
  return endDate;
};
