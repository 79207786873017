import axios from "axios";

import { errorHandler, commonRes } from "./ErrorHandler";

export const getImageDetails = async (data) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL,
      manufacturer = localStorage.getItem("manufacturer");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const res = await axios.get(
        `${backend_url}/api/viewOneImage/getImageDetails?audit_id=${data.audit_id}&image_index=${data.image_index}`,
        { headers: headers }
      );
      return commonRes(false, { ...res.data });
    } catch (err) {
      return errorHandler(err, getAuditByDate);
    }
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};

export const getProductDetails = async (data) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL,
      manufacturer = localStorage.getItem("manufacturer");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const res = await axios.get(
        `${backend_url}/api/products/displayImageUrls?product_id=${data.product_id}`,
        { headers: headers }
      );
      return commonRes(false, { ...res.data });
    } catch (err) {
      return errorHandler(err, getAuditByDate);
    }
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};

export const getAuditByDate = async (date) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL,
      manufacturer = localStorage.getItem("manufacturer");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const res = await axios.get(
        `${backend_url}/api/auditReview/getAudits?manufacturer=${manufacturer}&date=${date}`,
        { headers: headers }
      );
      return commonRes(false, { ...res.data });
    } catch (err) {
      return errorHandler(err, getAuditByDate);
    }
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};

export const updateShelfMarking = (body) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    return axios
      .post(
        `${backend_url}/api/shelfMarking/update`,
        { ...body },
        {
          headers: headers,
        }
      )
      .then((res) => res.data);
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};

export const updateLabels = (body) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    return axios
      .post(
        `${backend_url}/api/viewOneImage/updateLabels`,
        { ...body },
        {
          headers: headers,
        }
      )
      .then((res) => res.data);
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};
