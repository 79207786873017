import React, { useState } from "react";
import Sketch from "./Sketch";
import Rect from './Rect';

const Main = (props) => {
  const { shapes, setShapes, type } = props;

  return (
    <>
      {type === "annotate" && (
        <Sketch
          img_url={props.img_url}
          x={10}
          y={10}
          shapes={shapes}
          setShapes={setShapes}
        />
      )}
      {
        type === 'rect' && (
          <Rect 
            img_url={props.img_url}
            shapes={shapes}
            setShapes={setShapes}
          />
        )
      }
    </>
  );
};

export default Main;
