import React, { useState, useEffect } from "react";
import Sketch from "react-p5";

const SketchComp = (props) => {
  let drawFunction = draw.bind(this);
  let preloadFunction = preload.bind(this);
  let setupFunction = setup.bind(this);
  let mouseClickFunction = mouseClicked.bind(this);
  let mouseMovedFunction = mouseMoved.bind(this);

  // const {shapes, setShapes, mouseClicked} = props;
  const [isShapeActive, setIsShapeActive] = useState(false);
  const [activeShapeId, setActiveShapeId] = useState("");
  // const [activeShape, setActiveShape] = useState(-1);
  // const [isHovering, setIsHovering];
  const [isChanged, setIsChanged] = useState(false);
  const { shapes, setShapes } = props;
  const [imgLoading, setImgLoading] = useState(false);
  const [angle, setAngle] = useState(0);

  let canvasHeight = 500;
  let canvasWidth = 500;
  let landscape;
  let rectSize = 5;

  let maxCanvas = 500;

  const [imgData, setImgData] = useState({
    img: null,
    dx: 0,
    dy: 0,
    dw: maxCanvas,
    dh: maxCanvas,
    sx: 0,
    sy: 0,
    sw: maxCanvas,
    sh: maxCanvas,
  });

  useEffect(() => {
    let deleteEvent = (e) => {
      if (e.key === "Delete" || e.key === "Backspace") {
        // console.log(e, "delete Event");
        setShapes((pre) => {
          let newData = pre.filter((pre) => !pre.hover);
          newData = newData.map((dt, index) => {
            if (activeShapeId === dt.id) {
              setActiveShapeId(index);
            }
            dt.id = index;
            return dt;
          });
          // console.log("active shape id", activeShapeId);
          return [...newData];
        });
      }
    };

    window.addEventListener("keydown", (e) => deleteEvent(e));

    return () => window.removeEventListener("keypress", deleteEvent);
  }, []);

  useEffect(() => {
    setIsChanged(true);
  }, [props.img_url]);

  function preload(p5) {
    // landscape = p5.loadImage(props.img_url, img => {
    //   if(img?.width && img.width < 1200){
    //     canvasHeight = img.height || 1200;
    //     canvasWidth = img.width || 1200;
    //   }else{
    //     canvasWidth = 1200;
    //     canvasHeight = img ? img.height:1200;
    //   }
    // });
  }

  function ray_casting(point, vs) {
    // var n = polygon.length,
    //   is_in = false,
    //   x = point[0],
    //   y = point[1],
    //   x1,
    //   x2,
    //   y1,
    //   y2;
    // for (var i = 0; i < n - 1; ++i) {
    //   x1 = polygon[i][0];
    //   x2 = polygon[i + 1][0];
    //   y1 = polygon[i][1];
    //   y2 = polygon[i + 1][1];
    //   if (y < y1 != y < y2 && x < ((x2 - x1) * (y - y1)) / (y2 - y1) + x1) {
    //     is_in = !is_in;
    //   }
    // }
    // return is_in;

    var x = point[0],
      y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0],
        yi = vs[i][1];
      var xj = vs[j][0],
        yj = vs[j][1];

      var intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  function mouseMoved(p5, event) {
    if (event.target.className !== "p5Canvas") return false;
    let percentHeight = imgData.dh / window.sketchImg.height;
    let percentWidth = imgData.dw / window.sketchImg.width;

    shapes.map((shape) => {
      if (shape.isClosed) {
        let offsetX = event.offsetX / percentWidth;
        let offsetY = event.offsetY / percentHeight;

        let point = [offsetX, offsetY];
        // console.log(point);
        let polygon = [];
        shape.points.forEach((currPoint) => {
          polygon.push([currPoint.x, currPoint.y]);
        });

        let is_in = ray_casting(point, polygon);
        if (
          // offsetX > shape.points[0].x &&
          // offsetX > shape.points[3].x &&
          // offsetX < shape.points[1].x &&
          // offsetX < shape.points[2].x &&
          // offsetY > shape.points[0].y &&
          // offsetY > shape.points[1].y &&
          // offsetY < shape.points[2].y &&
          // offsetY < shape.points[3].y
          is_in
        ) {
          // console.log(shape.hover);
          if (!shape.hover) {
            setShapes((pre) => {
              let changedData = { ...pre[shape.id] };
              changedData.hover = true;
              pre[shape.id] = { ...changedData };
              return [...pre];
            });
          }
          if (p5.mousePressed === true) {
            shape.points.map((pt) => {});
          }
        } else {
          if (shape.hover) {
            setShapes((pre) => {
              let changedData = { ...pre[shape.id] };
              changedData.hover = false;
              pre[shape.id] = { ...changedData };
              return [...pre];
            });
          }
        }
      }
    });
  }

  function setup(p5, canvasParentRef) {
    let ctx = p5
      .createCanvas(canvasWidth, canvasHeight)
      .parent(canvasParentRef);
    window.sketchImg = p5.loadImage(props.img_url);
    // ctx.mousePressed((event) => {
    //   console.log("Clicked on the canvas. Event:", event);
    // });
  }

  function draw(p5) {
    if (isChanged) {
      setImgLoading(true);
      p5.loadImage(props.img_url, (imgLoaded) => {
        window.sketchImg = imgLoaded;
        let canH, canW;
        if (imgLoaded.width > imgLoaded.height) {
          canW = maxCanvas;
          canH = (imgLoaded.height / imgLoaded.width) * maxCanvas;
        } else {
          canH = maxCanvas;
          canW = (imgLoaded.width / imgLoaded.height) * maxCanvas;
        }
        p5.resizeCanvas(canW, canH);
        setImgData((pre) => {
          return {
            ...pre,
            img: imgLoaded,
            sw: imgLoaded.width,
            sh: imgLoaded.height,
            dw: canW,
            dh: canH,
          };
        });
        setImgLoading(false);
      });
      setIsChanged(false);
    }
    if (imgLoading) {
      props.onload(true)
      p5.translate(imgData.dw / 2, imgData.dh / 2);
      p5.background(51);
      p5.strokeWeight(4);
      p5.stroke(255, 204, 100);
      p5.noFill();
      p5.rotate(angle);
      p5.arc(0, 0, 80, 80, 0, p5.PI + p5.QUARTER_PI);
      setAngle((pre) => pre + 0.1);
      return;
    } else {
      props.onload(false)
    }
    p5.clear();
    p5.cursor("crosshair");
    let percentHeight = imgData.dh / window.sketchImg.height;
    let percentWidth = imgData.dw / window.sketchImg.width;
    p5.image(
      window.sketchImg,
      imgData.dx,
      imgData.dy,
      imgData.dw,
      imgData.dh,
      imgData.sx,
      imgData.sy,
      imgData.sw,
      imgData.sh
    );
    shapes.map((shape) => {
      shape.points.map((point) => {
        p5.strokeWeight(1);
        p5.stroke(11);
        p5.fill(shape.color.substr(0, 7));
        p5.rect(
          point.x * percentWidth - rectSize / 2,
          point.y * percentHeight - rectSize / 2,
          rectSize,
          rectSize
        );
      });
      if (shape.isClosed) {
        p5.fill(shape.color);
        p5.beginShape();
        shape.points.map((point) => {
          if (shape.hover) {
            p5.strokeWeight(5);
            p5.stroke(11);
            p5.vertex(point.x * percentWidth, point.y * percentHeight);
          }
          p5.vertex(point.x * percentWidth, point.y * percentHeight);
        });
        p5.endShape(p5.CLOSE);
      }
    });
  }

  const randomColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6) + "80";
  };

  function mouseClicked(p5, e) {
    if (e.target.className !== "p5Canvas") return false;
    let isHovering = false;
    shapes.forEach((shape) => {
      if (shape.hover) isHovering = true;
    });
    if (isHovering) return false;

    // console.log('mouse clicked event e : ', e);

    // delta height w.r.t canvas height
    let percentHeight = imgData.dh / window.sketchImg.height;
    let percentWidth = imgData.dw / window.sketchImg.width;

    if (isShapeActive) {
      if (shapes[activeShapeId]?.points?.length >= 4) {
        setIsShapeActive(false);
        setShapes((pre) => {
          let newData = [...pre];
          let updateData = { ...newData[activeShapeId] };
          updateData.isClosed = true;
          newData[activeShapeId] = updateData;
          return [...newData];
        });
      } else {
        setShapes((pre) => {
          let newData = [...pre];
          let updateData = { ...newData[activeShapeId] };
          // (mouse pointer - canvas left) / width delta ;
          // (mouse pointer - canvas top) / height delta ;
          let newCoordinates = {
            x: (e.pageX - e.target.offsetLeft) / percentWidth,
            y: (e.pageY - e.target.offsetTop) / percentHeight,
          };
          // console.log(newCoordinates, 'new coordinates');
          updateData.points = [...updateData.points, newCoordinates];
          if (updateData.points.length === 4) {
            updateData.isClosed = true;
            setIsShapeActive(false);
            // let newPoints = [...updateData.points];
            // let shapePointsYSort = newPoints.sort((a, b) => {
            //   return a.y - b.y;
            // });
            // console.log(shapePointsYSort, "points after y sort");
            // newPoints = [...shapePointsYSort];
            // if (newPoints.length >= 2 && newPoints[0].x > newPoints[1].x) {
            //   let obj1 = { ...newPoints[0] };
            //   newPoints[0] = { ...newPoints[1] };
            //   newPoints[1] = { ...obj1 };
            // }
            // if (newPoints.length >= 4 && newPoints[2].x < newPoints[3].x) {
            //   let obj1 = { ...newPoints[2] };
            //   newPoints[2] = { ...newPoints[3] };
            //   newPoints[3] = { ...obj1 };
            // }
            // if (newPoints[3].x > newPoints[1].x) {
            //   let secondPoint = newPoints[1];
            //   newPoints[1] = newPoints[2];
            //   newPoints[2] = newPoints[3];
            //   newPoints[3] = secondPoint;
            // }
            // console.log([...newPoints], "points after condition 1");

            // if (newPoints[0].x > newPoints[2].x) {
            //   let tempPoints = [
            //     newPoints[2],
            //     newPoints[0],
            //     newPoints[1],
            //     newPoints[3],
            //   ];
            //   newPoints = [...tempPoints];
            // }
            // console.log([...newPoints], "points after condition 2");
            // updateData.points = [...newPoints];
          }
          newData[activeShapeId] = updateData;
          return [...newData];
        });
      }
    } else {
      let newId = shapes.length;
      setIsShapeActive(true);
      setActiveShapeId(newId);
      // (mouse pointer - canvas left) / width delta ;
      // (mouse pointer - canvas top) / height delta ;
      let point = {
        x: (e.pageX - e.target.offsetLeft) / percentWidth,
        y: (e.pageY - e.target.offsetTop) / percentHeight,
      };
      setShapes((pre) => {
        let newData = {
          id: newId,
          color: randomColor(),
          isClosed: false,
          points: [point],
        };
        return [...pre, newData];
      });
    }
    return false;
  }

  const mousePressed = () => {
    // console.log('mouse pressed')
  };

  const mouseReleased = () => {
    // console.log('mouse released');
  };

  const mouseDragged = (p5, e) => {
    // console.log('mouse is dragging')
  };

  return (
    <Sketch
      preload={preloadFunction}
      setup={setupFunction}
      draw={drawFunction}
      mouseClicked={mouseClickFunction}
      mouseMoved={mouseMovedFunction}
      mousePressed={mousePressed}
      mouseReleased={mouseReleased}
      mouseDragged={mouseDragged}
    />
  );
};

export default SketchComp;
