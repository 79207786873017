import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { userActions } from "../../actions";
import image from "../../public/logo.png";

import { getManufacturers } from "../../API/Manufacturers";

import "./Login.scss";
//import { history } from "../../helpers";

function OptionPage() {
  const [inputs, setInputs] = useState({
    manufacturer: "",
  });
  const [submitted, setSubmitted] = useState(false);
  //const loggingIn = useSelector((state) => state.authentication.loggingIn);
  useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const location = useLocation();
  const [options, setOptions] = useState([{ label: "test", value: "test" }]);
  const [qa_manufacturers, setQa_manufacturers] = useState([]);

  const style = {
    maincontent: {
      backgroundColor: "#ffffff",
      borderRadius: 10,
      padding: 20,
      margin: 15,
      boxShadow: "1px 1px 5px 5px #eee",
    },
  };

  // reset login status
  useEffect(() => {
    /*let user = JSON.parse(localStorage.getItem("user"));
    var options = [];
    if (user.qa_manufacturers)
      for (let ind = 0; ind < user.qa_manufacturers.length; ind++) {
        options.push({
          label: user.qa_manufacturers_name[ind],
          value: user.qa_manufacturers[ind],
        });
      }
    setOptions(options);*/
    // var options = [];
    // const requestOptions = {
    //   method: "GET",
    //   headers: { "Content-Type": "application/json" },
    //   headers: {
    //     authorization: "Bearer " + localStorage.getItem("access_token"),
    //   },
    // };

    // const url = `${process.env.REACT_APP_BACKEND_SERVER}/api/user/qa_manufacturers`;

    // fetch(url, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     //localStorage.setItem("user", JSON.stringify(user));
    //     //console.log(data);
    //     //return user;
    //     for (let ind = 0; ind < data.length; ind++) {
    //       options.push({
    //         label: data[ind].name,
    //         value: data[ind]._id,
    //       });
    //     }
    //     setOptions(options);
    //   });

    getManufacturers().then((res) => {
      let formattedOptions = [];
      res.data.qa_manufacturers.forEach((manufacturer) => {
        formattedOptions.push({
          label: manufacturer.name,
          value: manufacturer._id,
        });
      });
      setOptions([...formattedOptions]);
      localStorage.setItem("role", res.data.qa_level);
      localStorage.setItem("mode", res.data.qa_level);
    });
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const updateManufacturer = (value) => {
    let manufacturers = [];
    manufacturers.push(value);
    setQa_manufacturers(manufacturers);
  };

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (qa_manufacturers.length > 0) {
      //let user = JSON.parse(localStorage.getItem("user"));
      //user.qa_manufacturers = qa_manufacturers;
      //localStorage.setItem("user", JSON.stringify(user));
      //history.push("/");
      localStorage.setItem("manufacturer", qa_manufacturers[0]);
      window.location = "/home";
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <form className="col-3 login-form" name="form" onSubmit={handleSubmit}>
        <div className="form-group logo">
          <img src={image} />
        </div>
        <div className="form-group">
          <label>Select manufacturer</label>
          <Select
            className={
              "" +
              (submitted && qa_manufacturers.length <= 0 ? " is-invalid" : "")
            }
            name="manufacturer"
            options={options}
            onChange={(newValue) => {
              updateManufacturer(newValue.value);
            }}
          />
          {submitted && qa_manufacturers.length <= 0 && (
            <div className="invalid-feedback">Please select manufacturer</div>
          )}
        </div>
        <div className="form-group">
          <button className="btn btn-primary">
            {/*{loggingIn && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}*/}
            Continue
          </button>
          {/*<Link to="/register" className="btn btn-link">Register</Link>*/}
        </div>
      </form>
    </div>
  );
}

export default OptionPage;
