import axios from "axios";

import { AuthService } from "../services/auth.service";

export const Logout = () => {
  var authService = new AuthService();
  localStorage.clear();
  authService.logout();
  ClearClaims();
  window.location.href = "/";
};

export const ClearClaims = async () => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const res = await axios.get(`${backend_url}/api/user/logout`, {
      headers: headers,
    });
    return res.data;
  } else {
    return { message: "error! token missing" };
  }
};
