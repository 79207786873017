import React, { useEffect, useState } from "react";
import Sketch from "react-p5";

let imgLoaded;

export default (props) => {
  const maxCanvas = 500;
  const { img, imageX, imageY, data } = props;

  const [isChanged, setIsChanged] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    setIsChanged(true);
  }, [img]);

  const [startPoint, setStartPoint] = useState({
    x: 0,
    y: 0,
  });

  const [currentPoint, setCurrentPoint] = useState({
    x: 0,
    y: 0,
  });

  const [imgData, setImgData] = useState({
    img: null,
    dx: 0,
    dy: 0,
    dw: imageX,
    dh: imageY,
    sx: 0,
    sy: 0,
    sw: 0,
    sh: 0,
  });

  const preload = (p5) => {
    // imgLoaded = p5.loadImage(img, (loadedImage) => {
    //   let sxNew = 0,
    //     syNew = 0,
    //     swidth = loadedImage.width,
    //     sHeight = loadedImage.height;
    //   let imgRatio;
    //   if (swidth > sHeight) {
    //     imgRatio = swidth / sHeight;
    //   } else {
    //     imgRatio = sHeight / swidth;
    //   }
    //   let dwidth, dHeight;
    //   if (swidth < 200 || sHeight < 200) {
    //     dwidth = swidth + 10 * imgRatio;
    //     dHeight = sHeight + 10 * imgRatio;
    //   }
    //   setImgData((pre) => {
    //     return {
    //       ...pre,
    //       img: loadedImage,
    //       dw: imageX,
    //       dh: imageY,
    //       sx: sxNew,
    //       sy: syNew,
    //       sw: swidth,
    //       sh: sHeight,
    //     };
    //   });
    // });
  };

  const loadImage = (p5) => {
    setImgLoading(true);
    p5.loadImage(img, (loadedImage) => {
      let sxNew = 0,
        syNew = 0,
        swidth = loadedImage.width,
        sHeight = loadedImage.height;

      let dwidth, dHeight;

      if (swidth > sHeight) {
        dwidth = maxCanvas;
        dHeight = (swidth / sHeight) * maxCanvas;
      } else {
        dHeight = maxCanvas;
        dwidth = (swidth / sHeight) * maxCanvas;
      }

      p5.resizeCanvas(dwidth, dHeight);

      setImgData((pre) => {
        return {
          ...pre,
          img: loadedImage,
          dw: dwidth,
          dh: dHeight,
          sx: sxNew,
          sy: syNew,
          sw: swidth,
          sh: sHeight,
        };
      });
      setImgLoading(false);
    });
  };

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(imgData.dw, imgData.dh).parent(canvasParentRef);
  };

  const mouseDragged = (p5, event) => {
    if (event.target.className !== "p5Canvas") return false;

    let newX = startPoint.x - event.x,
      newY = startPoint.y - event.y;

    setCurrentPoint({
      x: newX,
      y: newY,
    });
  };

  const mouseReleased = (p5, event) => {
    p5.clear();
    let currentX = currentPoint.x,
      currentY = currentPoint.y;
    setImgData((pre) => {
      return {
        ...pre,
        sx: pre.sx + currentX,
        sy: pre.sy + currentY,
      };
    });
    setCurrentPoint({
      x: 0,
      y: 0,
    });
  };

  const mouseWheel = (p5, event) => {
    if (event.target.className !== "p5Canvas") return false;
    event.preventDefault();
    let currentDimension = imgData.sw + imgData.sh;
    let ScaleFactor = (currentDimension + 2 * event.delta) / currentDimension;
    // if (event.delta < 0 && (imgData.sw <= 100 || imgData.sh <= 100)) {
    //   console.log('in lesser');
    //   return;
    // }
    // if (event.delta > 0 && (imgData.dw >= 1000 || imgData.dh >= 1000)) {
    //   console.log('in greater')
    //   return;
    // }

    setImgData((pre) => {
      return {
        ...pre,
        // dw: pre.dw + event.delta,
        // dh: pre.dh + event.delta,
        sw: pre.sw * ScaleFactor,
        sh: pre.sh * ScaleFactor,
      };
    });
  };

  const mousePressed = (p5, event) => {
    if (event.target.className !== "p5Canvas") return false;
    setStartPoint((pre) => {
      return {
        x: event.x,
        y: event.y,
      };
    });
  };

  const draw = (p5) => {
    // console.log("in draw....");
    if (isChanged) {
      loadImage(p5);
      setIsChanged(false);
    }
    if (imgLoading || imgData.img == null) {
      p5.translate(imgData.dw / 2, imgData.dh / 2);
      p5.background(51);
      p5.strokeWeight(4);
      p5.stroke(255, 204, 100);
      p5.noFill();
      p5.rotate(angle);
      p5.arc(0, 0, 80, 80, 0, p5.PI + p5.QUARTER_PI);
      setAngle((pre) => pre + 0.1);
      return;
    }
    p5.clear();
    p5.fill(p5.color(0, 0, 0));
    p5.image(
      imgData.img,
      imgData.dx,
      imgData.dy,
      imgData.dw,
      imgData.dh,
      imgData.sx + currentPoint.x,
      imgData.sy + currentPoint.y,
      imgData.sw,
      imgData.sh
    );
  };

  return (
    <Sketch
      setup={setup}
      preload={preload}
      draw={draw}
      mousePressed={mousePressed}
      mouseDragged={mouseDragged}
      mouseReleased={mouseReleased}
      mouseWheel={mouseWheel}
    />
  );
};
