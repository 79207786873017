import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { userActions } from "../../actions";
import image from "../../public/logo.png";
import "../../css/Login.css";
import {
  Log,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
  InMemoryWebStorage,
} from "oidc-client";
import { AuthService } from "../../services/auth.service";

function Login() {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { username, password } = inputs;
  //const loggingIn = useSelector((state) => state.authentication.loggingIn);
  useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const location = useLocation();

  const style = {
    maincontent: {
      backgroundColor: "#ffffff",
      borderRadius: 10,
      padding: 20,
      margin: 15,
      boxShadow: "1px 1px 5px 5px #eee",
    },
  };

  var authService = new AuthService();

  useEffect(() => {
    localStorage.clear();
    authService.logout();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        src="https://milkyway.ai/wp-content/uploads/2021/12/Group-4d1.png"
        style={{ width: 300 }}
      />
    </div>
  );
}

export default Login;
