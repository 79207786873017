import axios from "axios";

import { errorHandler, commonRes } from "./ErrorHandler";

export const getNextLabelVerification = async (params) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL,
      manufacturer = localStorage.getItem("manufacturer");
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
      params: {
        manufacturer: manufacturer,
      },
    };

    if (params.qa_level && params.qa_level === "ordinary") {
      config.params.qa_level = params.qa_level;
    }
    try {
      const res = await axios.get(`${backend_url}/api/labels/getNext`, config);
      return commonRes(false, res.data);
    } catch (err) {
      return errorHandler(err, getNextLabelVerification);
    }
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};

export const updateLabelVerification = (body) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    return axios
      .post(
        `${backend_url}/api/labels/update`,
        { ...body },
        {
          headers: headers,
        }
      )
      .then((res) => commonRes(false, res.data))
      .catch((err) => errorHandler(err, updateLabelVerification, body));
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};

export const getAvgPrice = async (product_id) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    // manufacturer = localStorage.getItem("manufacturer");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const res = await axios.get(
        `${backend_url}/api/productPricings/avgPrice?product_id=${product_id}`,
        { headers: headers }
      );
      return commonRes(false, res.data);
    } catch (err) {
      return errorHandler(err, getAvgPrice);
    }
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};

export const getImgUrls = async (params) => {
  let access_token = localStorage.getItem("access_token");
  let backend_url = process.env.REACT_APP_BACKEND_URL;
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    params: params,
  };

  if (access_token.length > 0) {
    try {
      const res = await axios.get(
        `${backend_url}/api/products/displayImageUrls`,
        config
      );
      console.log(res);
      return commonRes(false, res.data);
    } catch (err) {
      return errorHandler(err, getImgUrls, params);
    }
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};
