import { AuthService } from "../services/auth.service";

export const renewToken = async () => {
  var authService = new AuthService();
  await authService.renewToken();
  let user = await authService.getUser();
  if (user && user.access_token) {
    localStorage.setItem("access_token", user.access_token);
    return true;
  } else {
    return false;
  }
};
