import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ModeProvider } from "./context/ModeContext";

import MainSketch from "./pages/sketch/Main";
import Bbox from "./pages/Bbox/Bbox";
import Home from "./pages/Home/Home";
import OptionPage from "./pages/Login/OptionPage";
import Login from "./pages/Login/Login";
import Logout from "./pages/Login/Logout";
import LabelVerification from "./pages/LabelVerification/LabelVerification";
import PricingQA from "./pages/PricingQA/PricingQA";
import ViewOneImage from "./pages/ViewOneImage/ViewOneImage";
import AuditReview from "./pages/AuditReview/AuditReview";

import "./css/global.css";

function App() {
  let img_url =
    "https://media.istockphoto.com/photos/pasta-on-supermarket-shelves-picture-id1299861504?b=1&k=20&m=1299861504&s=170667a&w=0&h=3UHsvWsc77Nh0SZIi6iigN82qYKBSfO0eJ_Of1KqXCA=";

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <div className="App">
      <ModeProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/home" element={<Home />} />
            <Route path="/option" element={<OptionPage />} />
            <Route path="/bbox" element={<Bbox />} />
            <Route path="/sketch" element={<MainSketch img_url={img_url} />} />
            <Route path="/labelVerification" element={<LabelVerification />} />
            <Route path="/pricingQA" element={<PricingQA />} />
            <Route path="/auditReview" element={<AuditReview />} />
            <Route path="/viewOneImage" element={<ViewOneImage />} />
          </Routes>
        </BrowserRouter>
      </ModeProvider>
    </div>
  );
}

export default App;
