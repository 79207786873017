import { createContext, useState } from "react";

export const ModeContext = createContext({});

export const ModeProvider = (props) => {
  const role = localStorage.getItem("role");

  const getMode = () => {
    let mode = localStorage.getItem("mode");
    if (!mode) {
      localStorage.setItem("mode", role);
      mode = role;
    }
    return mode;
  };

  const initialState = getMode();

  const [currentMode, setCurrentMode] = useState(initialState);

  return (
    <ModeContext.Provider value={[currentMode, setCurrentMode]}>
      {props.children}
    </ModeContext.Provider>
  );
};
