import React, { useState, useEffect } from "react";
import Sketch from "react-p5";

const RectComp = (props) => {
  const { shapes, setShapes } = props;

  const [isShapeActive, setIsShapeActive] = useState(false);
  const [activeShapeId, setActiveShapeId] = useState("");
  const [activeStartingPoint, setActiveStartingPoint] = useState({
    x: 0,
    y: 0,
  });

  const [currentDraggedPoints, setCurrentDraggedPoints] = useState({
    x: 0,
    y: 0,
  });

  const [isChanged, setIsChanged] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [angle, setAngle] = useState(0);

  // const [ctrlPressed, setCtrlPressed] = useState(true);

  let drawFunction = draw.bind(this);
  let setupFunction = setup.bind(this);
  let mouseClickFunction = mouseClicked.bind(this);
  let mouseMovedFunction = mouseMoved.bind(this);
  let mouseDraggedFunction = mouseDragged.bind(this);
  let mouseReleasedFunction = mouseReleased.bind(this);
  let mouseWheelFunction = mouseWheel.bind(this);

  let canvasHeight = 500;
  let canvasWidth = 500;
  let rectSize = 5;

  let maxCanvas = 500;

  useEffect(() => {
    let deleteEvent = (e) => {
      if (e.key === "Delete" || e.key === "Backspace") {
        setShapes((pre) => {
          let newData = pre.filter((pre) => !pre.hover);
          newData = newData.map((dt, index) => {
            if (activeShapeId === dt.id) {
              setActiveShapeId(index);
            }
            dt.id = index;
            return dt;
          });
          return [...newData];
        });
      }
    };

    window.addEventListener("keydown", (e) => deleteEvent(e));

    return () => window.removeEventListener("keypress", deleteEvent);
  }, []);

  useEffect(() => {
    setIsChanged(true);
  }, [props.img_url]);

  const [imgData, setImgData] = useState({
    img: null,
    dx: 0,
    dy: 0,
    dw: maxCanvas,
    dh: maxCanvas,
    sx: 0,
    sy: 0,
    sw: maxCanvas,
    sh: maxCanvas,
  });
  const [zoom, setZoom] = useState(1);

  function mouseWheel(p5, e) {
    if (e.target.className !== "p5Canvas") return false;
    e.preventDefault();

    let tempZoom = zoom - e.delta / 1000;

    let percentHeight = imgData.dh / window.rectImg.height;
    let percentWidth = imgData.dw / window.rectImg.width;

    let canvasX = e.pageX - e.target.offsetLeft;
    let canvasY = e.pageY - e.target.offsetTop;

    // let imageleftold = canvasX * (imgData.sw / zoom);
    // let imagetopold = canvasY * (imgData.sh / zoom);

    // let imageleftnew = canvasX * (imgData.sw / tempZoom);
    // let imagetopnew = canvasY * (imgData.sh / tempZoom);

    // console.log(leftPercent * imgData.sw, canvasX / percentWidth / tempZoom);

    // console.log(
    //   imageleftold,
    //   imageleftnew,
    //   imagetopold,
    //   imagetopnew,
    //   canvasX,
    //   canvasY
    // );
    // console.log(imageleftold - imageleftnew, imagetopold - imagetopnew);

    let cpw = (canvasX / imgData.dw) * imgData.sw;
    let cph = (canvasY / imgData.dh) * imgData.sh;

    let leftPercent = (imgData.sx + cpw / zoom) / imgData.sw;
    let topPercent = (imgData.sy + cph / zoom) / imgData.sh;

    // let newSx = imgData.sx + cpw * (zoom - tempZoom);
    // let newSy = imgData.sy + cph * (zoom - tempZoom);

    let newSx = leftPercent * imgData.sw - cpw / tempZoom;
    let newSy = topPercent * imgData.sh - cph / tempZoom;

    if (tempZoom <= 1) {
      tempZoom = 1;
      setImgData((pre) => {
        return {
          ...pre,
          sx: 0,
          sy: 0,
        };
      });
      setZoom(tempZoom);
      return;
    }

    /* 
      new sx = old sx + (dw) * percent left on canvas;
      new sy = old sy + (dy) * percent top on canvas;
      dw = imgwidth / zoom - imgwidth/ tempzoom;
      dh = imgheight / zoom - imgHeight / tempzoom;
    */

    // setImgData(pre => {
    //   return{
    //     ...pre,
    //     sx : pre.sx + (imgData.sw / zoom )
    //   }
    // })

    setImgData((pre) => ({
      ...pre,
      sx: newSx,
      sy: newSy,
    }));

    // setImgData((pre) => ({
    //   ...pre,
    //   sx: pre.sx + ((e.pageX - e.target.offsetLeft) * percentWidth) / tempZoom,
    //   sy: pre.sy + ((e.pageY - e.target.offsetTop) * percentHeight) / tempZoom,
    // }));
    setZoom(tempZoom);
  }

  function mouseMoved(p5, event) {
    if (event.target.className !== "p5Canvas") return false;
    let percentHeight = imgData.dh / window.rectImg.height;
    let percentWidth = imgData.dw / window.rectImg.width;

    // console.log(shapes);

    shapes.map((shape) => {
      /*
        offset x = 
        images left in terms of  + mouse left
      */
      let offsetX = imgData.sx + event.offsetX / percentWidth / zoom;
      let offsetY = imgData.sy + event.offsetY / percentHeight / zoom;
      // let offsetY =
      //   (event.offsetY + ((imgData.sy / imgData.sh) * imgData.dh) / zoom) /
      //   percentHeight;
      if (
        offsetX > shape.points[0] &&
        offsetX < shape.points[2] &&
        offsetY > shape.points[1] &&
        offsetY < shape.points[3]
      ) {
        if (!shape.hover) {
          setShapes((pre) => {
            let changedData = { ...pre[shape.id] };
            changedData.hover = true;
            pre[shape.id] = { ...changedData };
            return [...pre];
          });
        }
        // console.log(shape, "shape");
      } else {
        if (shape.hover) {
          setShapes((pre) => {
            let changedData = { ...pre[shape.id] };
            changedData.hover = false;
            pre[shape.id] = { ...changedData };
            return [...pre];
          });
        }
      }
    });
  }

  function setup(p5, canvasParentRef) {
    let ctx = p5
      .createCanvas(canvasWidth, canvasHeight)
      .parent(canvasParentRef);
    window.rectImg = p5.loadImage(props.img_url);
  }

  const gradientSetup = (p5, sx, sy, dx, dy, sc, dc) => {
    let gradient = p5.drawingContext.createLinearGradient(sx, sy, dx, dy);
    gradient.addColorStop(0, sc);
    gradient.addColorStop(1, dc);
    p5.drawingContext.strokeStyle = gradient;
  };

  function draw(p5) {
    // console.log("is changed", isChanged);
    if (isChanged) {
      setImgLoading(true);
      p5.loadImage(props.img_url, (imgLoaded) => {
        window.rectImg = imgLoaded;
        let canH, canW;
        if (imgLoaded.width > imgLoaded.height) {
          canW = maxCanvas;
          canH = (imgLoaded.height / imgLoaded.width) * maxCanvas;
        } else {
          canH = maxCanvas;
          canW = (imgLoaded.width / imgLoaded.height) * maxCanvas;
        }
        p5.resizeCanvas(canW, canH);
        setImgData((pre) => {
          return {
            ...pre,
            img: imgLoaded,
            sw: imgLoaded.width,
            sh: imgLoaded.height,
            dw: canW,
            dh: canH,
          };
        });
        setImgLoading(false);
      });
      setIsChanged(false);
    }
    if (imgLoading) {
      p5.translate(imgData.dw / 2, imgData.dh / 2);
      p5.background(51);
      p5.strokeWeight(4);
      p5.stroke(255, 204, 100);
      p5.noFill();
      p5.rotate(angle);
      p5.arc(0, 0, 80, 80, 0, p5.PI + p5.QUARTER_PI);
      setAngle((pre) => pre + 0.1);
      return;
    }
    p5.clear();
    if (p5.keyCode === p5.CONTROL) {
      p5.cursor("grab");
    } else {
      p5.cursor("crosshair");
    }
    // let percentHeight = canvasHeight / window.rectImg.height;
    // let percentWidth = canvasWidth / window.rectImg.width;
    let percentHeight = imgData.dh / window.rectImg.height;
    let percentWidth = imgData.dw / window.rectImg.width;
    p5.image(
      window.rectImg,
      imgData.dx,
      imgData.dy,
      imgData.dw,
      imgData.dh,
      imgData.sx,
      imgData.sy,
      imgData.sw / zoom,
      imgData.sh / zoom
    );
    if (isShapeActive) {
      let startingX = activeStartingPoint.x * percentWidth,
        startingY = activeStartingPoint.y * percentHeight,
        width = currentDraggedPoints.x * percentWidth - startingX,
        height = currentDraggedPoints.y * percentHeight - startingY;
      p5.fill("#0080FF80");
      p5.rect(startingX, startingY, width, height);
    }
    shapes.map((shape, index) => {
      // p5.noStroke();
      p5.strokeWeight(2);
      p5.stroke(150, 200, 250);
      p5.fill(shape.color);
      if (shape.hover) {
        p5.strokeWeight(6);
      }

      // calculate percentage(image displaced width divided by original image width) then find value respect to 500
      let displaceX = (imgData.sx / imgData.sw) * imgData.dw;
      let displaceY = (imgData.sy / imgData.sh) * imgData.dh;
      let startingX = shape.points[0] * percentWidth - displaceX,
        startingY = shape.points[1] * percentHeight - displaceY,
        width = shape.points[2] * percentWidth - shape.points[0] * percentWidth,
        height =
          shape.points[3] * percentHeight - shape.points[1] * percentHeight;
      p5.rect(startingX * zoom, startingY * zoom, width * zoom, height * zoom);
    });
  }

  const randomColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6) + "50";
  };

  function mouseClicked(p5, e) {
    // console.log(e, "event");
    if (e.target.className !== "p5Canvas") return false;
    // console.log(p5.keyCode, p5.CONTROL);
    if (p5.keyCode === p5.CONTROL) {
      let percentHeight = imgData.dh / window.rectImg.height;
      let percentWidth = imgData.dw / window.rectImg.width;
      setActiveStartingPoint({
        x: e.pageX - e.target.offsetLeft,
        y: e.pageY - e.target.offsetTop,
      });
      setCurrentDraggedPoints({
        x: (e.pageX - e.target.offsetLeft) / percentWidth,
        y: (e.pageY - e.target.offsetTop) / percentHeight,
      });
      return;
    }
    let isHovering = false;
    shapes.forEach((shape) => {
      if (shape.hover) isHovering = true;
    });
    if (isHovering) return false;

    if (!isShapeActive) {
      // console.log("-----------getting--------------");
      let percentHeight = imgData.dh / window.rectImg.height;
      let percentWidth = imgData.dw / window.rectImg.width;
      setIsShapeActive(true);
      setActiveStartingPoint({
        x: (e.pageX - e.target.offsetLeft) / percentWidth,
        y: (e.pageY - e.target.offsetTop) / percentHeight,
      });
      // console.log(e.pageX, e.target.offsetLeft, percentWidth);
      // console.log(
      //   (e.pageX - e.target.offsetLeft) / percentWidth,
      //   (e.pageY - e.target.offsetTop) / percentHeight,
      //   "selectedpoint"
      // );
      setCurrentDraggedPoints({
        x: (e.pageX - e.target.offsetLeft) / percentWidth,
        y: (e.pageY - e.target.offsetTop) / percentHeight,
      });
      // console.log("mouse clicked");
    }
  }

  function mouseDragged(p5, e) {
    if (e.target.className !== "p5Canvas") return false;
    if (p5.keyCode === p5.CONTROL) {
      let percentHeight = imgData.dh / window.rectImg.height;
      let percentWidth = imgData.dw / window.rectImg.width;

      let newX = e.pageX - e.target.offsetLeft;
      let newY = e.pageY - e.target.offsetTop;

      let oldX = activeStartingPoint.x;
      let oldY = activeStartingPoint.y;

      let flag = false;

      if (imgData.sx - newX + oldX < 0) {
        setImgData((pre) => ({
          ...pre,
          sx: 0,
        }));
        console.log(imgData.sx, newX - oldX, imgData.sx - newX + oldX);
        flag = true;
      }
      if (imgData.sy - newY + oldY < 0) {
        setImgData((pre) => ({
          ...pre,
          sy: 0,
        }));
        flag = true;
      }

      if (!flag) {
        let finalX = (oldX - newX) / percentWidth / zoom;
        let finalY = (oldY - newY) / percentHeight / zoom;

        setImgData((pre) => ({
          ...pre,
          sx: pre.sx + finalX,
          sy: pre.sy + finalY,
        }));
        console.log(imgData.sx, newX - oldX, imgData.sx - newX + oldX);
        // return;
      }

      setActiveStartingPoint({
        x: newX,
        y: newY,
      });
      // console.log(imgData);
      return;
    }
    if (isShapeActive) {
      let percentHeight = imgData.dh / window.rectImg.height;
      let percentWidth = imgData.dw / window.rectImg.width;
      setCurrentDraggedPoints((pre) => {
        let leftof = e.pageX - e.target.offsetLeft;
        let topof = e.pageY - e.target.offsetTop;
        leftof = leftof < imgData.dw ? leftof : imgData.dw;
        topof = topof < imgData.dh ? topof : imgData.dh;
        pre.x = leftof / percentWidth;
        pre.y = topof / percentHeight;
        return pre;
      });
    }
  }

  function mouseReleased(p5, e) {
    if (isShapeActive) {
      setIsShapeActive(false);

      if (
        activeStartingPoint.x === currentDraggedPoints.x ||
        activeStartingPoint.y === currentDraggedPoints.y
      )
        return;

      let newInitialPoints = {
        x: imgData.sx + activeStartingPoint.x / zoom,
        y: imgData.sy + activeStartingPoint.y / zoom,
      };

      let newFinalPoints = {
        x: imgData.sx + currentDraggedPoints.x / zoom,
        y: imgData.sy + currentDraggedPoints.y / zoom,
      };

      const shape = {
        id: shapes.length,
        color: randomColor(),
        points: [],
      };

      let xcompare = activeStartingPoint.x > currentDraggedPoints.x;
      let ycompare = activeStartingPoint.y > currentDraggedPoints.y;

      shape.points = [
        xcompare ? newFinalPoints.x : newInitialPoints.x,
        ycompare ? newFinalPoints.y : newInitialPoints.y,
        xcompare ? newInitialPoints.x : newFinalPoints.x,
        ycompare ? newInitialPoints.y : newFinalPoints.y,
      ];

      setShapes((pre) => {
        pre.push({ ...shape });
        return pre;
      });

      // console.log(shapes);
    }
  }

  return (
    <Sketch
      setup={setupFunction}
      draw={drawFunction}
      mousePressed={mouseClickFunction}
      mouseMoved={mouseMovedFunction}
      mouseDragged={mouseDraggedFunction}
      mouseReleased={mouseReleasedFunction}
      mouseWheel={mouseWheelFunction}
    />
  );
};

export default RectComp;
