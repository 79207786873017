import React, { useEffect, useState, useContext, useRef } from "react";

import Header from "../../Components/common/Header";
import Main from "../sketch/Main";
import Sketch from "../sketch/Sketch";
import Loader from "../../Components/common/Loader";
import Timer from "../../Components/common/Timer";
import { DeleteOutlined } from "@ant-design/icons";
import { ModeContext } from "../../context/ModeContext";

import {
  getNextShelfMarking,
  updateShelfMarking,
} from "../../API/ShelfMarking";
import { Logout, ClearClaims } from "../../API/Logout";

import "../../css/Home.css";

const Home = () => {
  const initialImgData = {
    image_url: "",
    user: "",
    audit_id: "",
    store_name: "",
    client_name: "",
    image_index: 0,
    date: "",
    bay_number: "",
    bay_type: "",
    finalized_shelf_polygons: [],
  };

  const [imgData, setImgData] = useState({ ...initialImgData });
  const [shapes, setShapes] = useState([]);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [err, setErr] = useState(false);
  const role = localStorage.getItem("role");
  const [currentMode, setCurrentMode] = useContext(ModeContext);
  const isInitialMount = useRef(true);

  useEffect(() => {
    (async () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        let res = await ClearClaims();
        getShelfMarking();
      }
    })();
  }, [currentMode]);

  const randomColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6) + "50";
  };

  const getShelfMarking = () => {
    // ClearClaims();
    setLoading(true);
    const params = {};
    if (role === "expert" && currentMode === "ordinary") {
      params.qa_level = "ordinary";
    }
    getNextShelfMarking(params)
      .then((res) => {
        if (res.error) {
          setErr(true);
          alert(res.message);
          setImgData(initialImgData);
          if (res.errorCode && res.errorCode === "NO_ACCESS_TOKEN") {
            Logout();
          }
        } else {
          if (err) setErr(false);
          setImgData({ ...res.data });
          setSeconds(300);
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    getShelfMarking();
    return () => ClearClaims();
  }, []);

  const timerCallback = () => {
    setSeconds(0);
    getShelfMarking();
  };

  useEffect(() => {
    let img = new Image();
    let finalData = [];
    img.src = imgData.image_url;
    img.onload = function() {
      setLoading(true);
      setImageDimensions({
        width: this.width,
        height: this.height,
      });
      for (let [index, polygon] of imgData.finalized_shelf_polygons.entries()) {
        let newPoints = [];
        for (let [key, points] of polygon.entries()) {
          if (key === 0 || key % 2 === 0) {
            newPoints.push(points * this.height);
          } else {
            newPoints.push(points * this.width);
          }
        }
        let points = [];
        for (let i = 0; i < 8; i += 2) {
          points.push({ y: newPoints[i], x: newPoints[i + 1] });
        }
        let shapePointsYSort = points.sort((a, b) => {
          return a.y - b.y;
        });
        points = [...shapePointsYSort];
        if (points[0].x > points[1].x) {
          let obj1 = { ...points[0] };
          points[0] = { ...points[1] };
          points[1] = { ...obj1 };
        }
        if (points[2].x < points[3].x) {
          let obj1 = { ...points[2] };
          points[2] = { ...points[3] };
          points[3] = { ...obj1 };
        }
        let shape = {
          id: index,
          isClosed: true,
          color: randomColor(),
          points: [...points],
          hover: false,
        };
        finalData.push(shape);
      }
      setShapes([...finalData]);
      setLoading(false)
    };
  }, [imgData]);

  const sendData = () => {
    let newData = [];
    shapes.forEach((shape) => {
      if (shape.isClosed && shape.points.length === 4) {
        let newPoints = [];
        shape.points.forEach((point) => {
          newPoints.push(
            point.y / imageDimensions.height,
            point.x / imageDimensions.width
          );
        });
        newData.push(newPoints);
      }
    });

    let resBody = { ...imgData };
    resBody.finalized_shelf_polygons = [...newData];
    if (role === "expert" && currentMode === "ordinary") {
      resBody.qa_level = currentMode;
    }
    setLoading(true);
    updateShelfMarking(resBody).then((res) => {
      if (res.error) {
        alert(res.message);
      }
      setSeconds(0);
      getShelfMarking();
    }).finally(() => {
      setLoading(false)
    })
  };

  const skipData = () => {
    let role = localStorage.getItem("role");
    let resBody = { ...imgData };
    if (role === "ordinary") {
      resBody.escalate = true;
    } else {
      resBody.skip = true;
      resBody.qa_level = currentMode;
    }
    setLoading(true);
    updateShelfMarking(resBody).then((res) => {
      if (res.error) {
        alert(res.message);
      }
      setSeconds(0);
      getShelfMarking();
    }).finally(() => {
      setLoading(false)
    })
  };

  const setHoverShape = (id) => {
    shapes.forEach((shape) => {
      if (shape.id === id) {
        shape.hover = true;
      } else {
        shape.hover = false;
      }
    });
  };

  const deleteShape = (id) => {
    //find index of element
    var index = shapes.findIndex((e) => e.id == id);
    //copy array
    var newAray = shapes.slice();
    //delete element by index
    newAray.splice(index, 1);
    setShapes(newAray);
  };

  return (
    <>
      <div>
        {loading && <Loader />}
        <Header />
        <div style={{ padding: "20px" }} className="user-details">
          <p>
            <strong>Added By :</strong> {imgData.user}
          </p>
          <p>
            <strong>Audit ID :</strong> {imgData.audit_id}
          </p>
          <p>
            <strong>Store Name:</strong> {imgData.store_name}
          </p>
          <p>
            <strong>Manufacturer Name:</strong> {imgData.client_name}
          </p>
          <p>
            <strong>Image Index:</strong> {imgData.image_index}
          </p>
          <p>
            <strong>Date Added:</strong> {imgData.date}
          </p>
          <p>
            <strong>Bay number:</strong> {imgData.bay_number}
          </p>
          <p>
            <strong>Bay type:</strong> {imgData.bay_type}
          </p>
          {seconds && (
            <p>
              <strong>Time Remaining:</strong>{" "}
              <Timer seconds={seconds} callback={timerCallback} />
            </p>
          )}
        </div>
        {!err && (
          <div className="marking_box_container">
            <div className="marking_box">
              {shapes && (
                <Sketch
                  onload={(loading) => {
                    setLoading(loading)}}
                  img_url={imgData.image_url}
                  shapes={shapes}
                  setShapes={setShapes}
                />
              )}
            </div>
            <div
              className="row"
              style={{
                padding: 0,
              }}
            >
              <div className="col-sm-4">
                <div
                  className="row"
                  style={{
                    background: "#EFF2F5",
                    padding: 10,
                    border: "solid 1px #ccc",
                  }}
                >
                  <div className="col-sm-3">ID</div>
                  <div className="col-sm-9" style={{ textAlign: "center" }}>
                    Delete
                  </div>
                </div>
                {shapes &&
                  shapes.map((shape) => (
                    <div
                      className="row"
                      style={{
                        padding: 10,
                        border: "solid 0.5px #ccc",
                      }}
                    >
                      <div className="col-sm-3">
                        <a
                          href="#"
                          onMouseOver={() => {
                            setHoverShape(shape.id);
                          }}
                          onClick={() => {
                            setHoverShape(shape.id);
                          }}
                        >
                          {shape.id}
                        </a>
                      </div>
                      <div className="col-sm-9" style={{ textAlign: "center" }}>
                        <DeleteOutlined
                          style={{ fontSize: "16px", color: "#FF0000" }}
                          onMouseOver={() => {
                            setHoverShape(shape.id);
                          }}
                          onClick={() => {
                            deleteShape(shape.id);
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="verification_actions">
              <button className="success" disabled={loading} onClick={sendData}>
                Done
              </button>
              <button className="error" disabled={loading} onClick={skipData}>
                Skip
              </button>
              <p>
                Total Pending Images : <span>{imgData.pending || 0}</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
