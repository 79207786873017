import { combineReducers } from 'redux';

import { users } from './users.reducer';
import { alert } from './alert.reducer';

const rootReducer = combineReducers({
    //authentication,
    //registration,
    users,
    alert,
    //shelfTags,
    //priceScanChecks,
    //annotations,
    //bayMarking
});

export default rootReducer;