import axios from "axios";

import { errorHandler, commonRes } from "./ErrorHandler";

export const getNextShelfMarking = async (params) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL,
      manufacturer = localStorage.getItem("manufacturer");

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
      params: {
        manufacturer: manufacturer,
      },
    };

    if (params.qa_level && params.qa_level === "ordinary") {
      config.params.qa_level = params.qa_level;
    }
    try {
      const res = await axios.get(
        `${backend_url}/api/shelfMarking/getNext`,
        config
      );
      return commonRes(false, { ...res.data });
    } catch (err) {
      return errorHandler(err, getNextShelfMarking);
    }
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};

export const updateShelfMarking = (body) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token.length > 0) {
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    return axios
      .post(
        `${backend_url}/api/shelfMarking/update`,
        { ...body },
        {
          headers: headers,
        }
      )
      .then((res) => res.data);
  } else {
    return commonRes(
      true,
      "You're not logged in. please log in!",
      "NO_ACCESS_TOKEN"
    );
  }
};
