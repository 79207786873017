import React, { useEffect, useState, useContext, useRef } from "react";
import Select from "react-select";

import Header from "../../Components/common/Header";
import PanZoom from "../sketch/PanZoom";
import Loader from "../../Components/common/Loader";
import Timer from "../../Components/common/Timer";

import { ModeContext } from "../../context/ModeContext";

import {
  getNextPriceVerification,
  updatePriceVerification,
  getAvgPrice,
} from "../../API/PriceVerification";
import { ClearClaims } from "../../API/Logout";

import "./PricingQA.css";

const PricingQA = () => {
  const initialImgData = {
    image_url: "",
    user: "",
    price_scan_id: "",
    store_name: "",
    store_id: "",
    manufacturer_id: "",
    client_name: "",
    image_index: 0,
    date: "",
    country_id: "",
    country_name: "",
    product_id: [],
    price: [],
    price_type: [],
    avg_base_price: [],
    avg_discount_price: [],
    labels: [],
  };
  const [imgData, setImgData] = useState(initialImgData);

  const formTemplate = {
    barcode: "",
    productList: "",
    price: "",
    discountedPrice: "",
    discountedType: "",
  };

  const [formData, setFormData] = useState([{ ...formTemplate }]);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [err, setErr] = useState(false);
  const role = localStorage.getItem("role");
  const [currentMode, setCurrentMode] = useContext(ModeContext);
  const isInitialMount = useRef(true);

  useEffect(() => {
    (async () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        let res = await ClearClaims();
        getNextImg();
      }
    })();
  }, [currentMode]);

  useEffect(() => {
    getNextImg();
    return () => ClearClaims();
  }, []);

  const getNextImg = () => {
    // ClearClaims();
    setLoading(true);
    const params = {};
    if (role === "expert" && currentMode === "ordinary") {
      params.qa_level = "ordinary";
    }
    getNextPriceVerification(params)
      .then((response) => {
        if (response.error) {
          setErr(true);
          alert(response.message);
          setLoading(false);
          setImgData(initialImgData);
          return;
        }
        let res = response.data;
        if (err) setErr(false);
        setImgData((pre) => {
          return { ...pre, ...res };
        });
        if (res.product_id.length > 0) {
          let capturedProductId = [];
          res.product_id.forEach((product, index) => {
            if (capturedProductId.includes(product)) {
              let newIndex = capturedProductId.indexOf(product);
              setFormData((pre) => {
                if (res.price_type[index] === "Regular") {
                  pre[newIndex].price = res.price[index];
                } else {
                  pre[newIndex].discountedPrice = res.price[index];
                  pre[newIndex].discountedType = res.price_type[index];
                }

                return [...pre];
              });
            } else {
              capturedProductId.push(product);
              let newFormTemplate = { ...formTemplate };
              newFormTemplate.productList = { label: product, value: product };
              if (res.price_type[index] === "Regular") {
                newFormTemplate.price = res.price[index];
                newFormTemplate.discountedType = "";
              } else {
                newFormTemplate.discountedPrice = res.price[index];
                newFormTemplate.discountedType = res.price_type[index];
              }
              setFormData((pre) => {
                if (index === 0) {
                  return [{ ...newFormTemplate }];
                } else {
                  return [...pre, { ...newFormTemplate }];
                }
              });
            }
          });
        } else {
          setFormData([{ ...formTemplate }]);
        }
        setLoading(false);
        setSeconds(1200);
      })
      .catch((res) => setLoading(false));
  };

  const sendData = () => {
    let resBody = {
      price_scan_id: imgData.price_scan_id,
      image_index: imgData.image_index,
      store_id: imgData.store_id,
      store_name: imgData.store_name,
      manufacturer_id: imgData.manufacturer_id,
      product_id: [],
      price: [],
      price_type: [],
    };
    formData.forEach((data, index) => {
      if (data.price !== "" && data.price !== 0) {
        resBody.product_id.push(data.productList.value);
        resBody.price.push(data.price);
        resBody.price_type.push("Regular");
      }
      if (data.discountedPrice !== "" && data.discountedPrice !== 0) {
        resBody.product_id.push(data.productList.value);
        resBody.price.push(data.discountedPrice);
        resBody.price_type.push(data.discountedType);
      }
    });
    if (role === "expert" && currentMode === "ordinary") {
      resBody.qa_level = currentMode;
    }
    setLoading(true);
    updatePriceVerification(resBody).then((res) => {
      setSeconds(0);
      getNextImg();
    });
  };

  const skip = () => {
    let userType = localStorage.getItem("role");
    let resBody = {
      ...imgData,
    };
    if (userType === "ordinary") {
      resBody.escalate = true;
    } else {
      resBody.skip = true;
      resBody.qa_level = currentMode;
    }

    setLoading(true);
    updatePriceVerification(resBody).then((res) => {
      setSeconds(0);
      getNextImg();
    });
  };

  const addProdFn = () => {
    setFormData((pre) => {
      if (pre.length > 0) {
        return [...pre, { ...formTemplate }];
      } else {
        return [{ ...formTemplate }];
      }
    });
  };

  const deleteArray = (index) => {
    setFormData((pre) => {
      pre.splice(index, 1);
      return [...pre];
    });
  };

  return (
    <div>
      {loading && <Loader />}
      <Header />
      <div style={{ padding: "20px" }} className="user-details">
        <p>
          <strong>User:</strong> {imgData.user}
        </p>
        <p>
          <strong>Price Scan ID:</strong> {imgData.price_scan_id}
        </p>
        <p>
          <strong>Date Added:</strong> {imgData.date}
        </p>
        <p>
          <strong>Store Name:</strong> {imgData.store_name}
        </p>
        <p>
          <strong>Store ID:</strong> {imgData.store_id}
        </p>
        <p>
          <strong>Country Name:</strong> {imgData.country_name}
        </p>
        <p>
          <strong>Country ID:</strong> {imgData.country_id}
        </p>
        <p>
          <strong>Pending Images:</strong> {imgData.pending}
        </p>
        <p>
          <strong>Image Index:</strong> {imgData.image_index}
        </p>
        {seconds > 0 && (
          <p>
            <strong>Time Remaining:</strong>{" "}
            <Timer seconds={seconds} setSeconds={setSeconds} />
          </p>
        )}
      </div>
      {!err && (
        <div>
          <p style={{ textAlign: "center", padding: "5px" }}>
            image is too blur? &nbsp;
            <span>
              <span className="link-btn" onClick={skip}>
                skip
              </span>
            </span>
          </p>
        </div>
      )}
      {!err && (
        <div className="pricingqa-data">
          {imgData.image_url && (
            <PanZoom img={imgData.image_url} imageX={500} imageY={500} />
          )}
          <div>
            {formData.length > 0 ? (
              formData.map((data, index) => {
                return (
                  <div key={index}>
                    <MyForm
                      data={data}
                      key={index}
                      index={index}
                      deleteArray={deleteArray}
                      formData={formData}
                      setFormData={setFormData}
                      imgData={imgData}
                    />
                  </div>
                );
              })
            ) : (
              <div>
                <MyForm
                  data={{}}
                  index={1}
                  formData={formData}
                  setFormData={setFormData}
                  imgData={imgData}
                />
              </div>
            )}
            <div className="button-container">
              <div className="link-btn" onClick={(e) => addProdFn()}>
                Add Product
              </div>
              <button onClick={sendData}>Done</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const MyForm = (props) => {
  const { index, deleteArray, formData, setFormData, imgData } = props;

  const [avgBasePrice, setAvgBasePrice] = useState(0);
  const [avgDiscountPrice, setAvgDiscountPrice] = useState(0);
  const [allOptions, setAllOptions] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (imgData.avg_base_price?.length >= index + 1) {
      setAvgBasePrice(imgData.avg_base_price[index]);
    }

    if (imgData.avg_discount_price?.length >= index + 1) {
      setAvgDiscountPrice(imgData.avg_discount_price[index]);
    }

    setAllOptions((pre) => {
      let newOptions = imgData.labels.map((label) => ({
        label: label,
        value: label,
      }));
      return newOptions;
    });
  }, [imgData]);

  useEffect(() => {
    // console.log(allOptions);
    if (allOptions.length > 0) {
      let newOptions = allOptions.filter((option) => {
        let res = true;
        formData.forEach((data, currIndex) => {
          if (currIndex !== index) {
            if (data.productList.label === option.label) {
              res = false;
            }
          }
        });
        return res;
      });

      setOptions(newOptions);
    }
  }, [formData, allOptions]);

  const selectHandler = (selectedOption) => {
    getAvgPrice(selectedOption.value).then((res) => {
      console.log(res, "avg price response");
      if (!res.error) {
        setAvgBasePrice(res.data.avg_base_price || 0);
        setAvgDiscountPrice(res.data.avg_discount_price || 0);
      } else {
        setAvgBasePrice(0);
        setAvgDiscountPrice(0);
      }
    });
    setFormData((pre) => {
      pre[index].productList = selectedOption;
      return [...pre];
    });
  };

  // const selectHandler = (e) => {
  //   getAvgPrice(e.target.value).then((res) => {
  //     console.log(res, "avg price response");
  //     if (!res.error) {
  //       setAvgBasePrice(res.data.avg_base_price || 0);
  //       setAvgDiscountPrice(res.data.avg_discount_price || 0);
  //     } else {
  //       setAvgBasePrice(0);
  //       setAvgDiscountPrice(0);
  //     }
  //   });
  //   setFormData((pre) => {
  //     pre[index].productList = e.target.value;
  //     return [...pre];
  //   });
  // };

  return (
    <form>
      <div className="form-action-header">
        <div className="actions">
          <div>{index + 1}</div>
          <div
            className="delete-btn"
            onClick={(e) => {
              deleteArray(index);
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="info">
          <div className="avg-regular-price">
            AVG Regular Price: {avgBasePrice || 0}
          </div>
          <div className="avg-discount-price">
            AVG Discount Price: {avgDiscountPrice || 0}
          </div>
        </div>
      </div>
      <label htmlFor="">Barcode</label>
      <div>
        <input
          type="text"
          name=""
          id=""
          value={formData[index].barcode}
          onChange={(e) =>
            setFormData((pre) => {
              pre[index].barcode = e.target.value;
              return [...pre];
            })
          }
        />
      </div>

      <label htmlFor="">Product List</label>
      <div>
        <Select
          options={options}
          styles={{
            container: (provided, styles) => ({
              ...provided,
              width: "100%",
            }),
          }}
          value={formData[index].productList}
          onChange={selectHandler}
        />
        {/* <select
          name=""
          id=""
          value={formData[index].productList}
          onChange={(e) => selectHandler(e)}
        >
          <option value="">--select--</option>
          {imgData.labels.length > 0 &&
            imgData.labels
              .filter((label) => {
                let res = true;
                formData.forEach((data, currIndex) => {
                  if (currIndex !== index) {
                    if (data.productList === label) {
                      res = false;
                    }
                  }
                });
                return res;
              })
              .map((label, key) => {
                return (
                  <option key={key} value={label}>
                    {label}
                  </option>
                );
              })}
        </select> */}
      </div>

      <label htmlFor="">Price</label>
      <div>
        <input
          type="number"
          name=""
          id=""
          value={formData[index].price}
          onChange={(e) =>
            setFormData((pre) => {
              pre[index].price = e.target.value;
              return [...pre];
            })
          }
        />
      </div>

      <label htmlFor="">Discounted Price</label>
      <div>
        <input
          type="number"
          name=""
          id=""
          value={formData[index].discountedPrice}
          onChange={(e) =>
            setFormData((pre) => {
              pre[index].discountedPrice = e.target.value;
              return [...pre];
            })
          }
        />
      </div>

      <label htmlFor="Discounted Type">Discounted Type</label>
      <div>
        <select
          name=""
          id=""
          value={formData[index].discountedType}
          onChange={(e) =>
            setFormData((pre) => {
              pre[index].discountedType = e.target.value;
              return [...pre];
            })
          }
        >
          <option value="">--select--</option>
          <option value="bogo">BOGO</option>
          <option value="member-discount">Member Discount</option>
          <option value="other">Others</option>
        </select>
      </div>
    </form>
  );
};

export default PricingQA;
