import React, { useEffect, useState } from "react";
import Sketch from "react-p5";

let imgLoaded;

const Draggable = (props) => {
  let drawFunction = draw.bind(this);
  let preloadFunction = preload.bind(this);
  let setupFunction = setup.bind(this);
  let mousePressedFunction = mousePressed.bind(this);
  let mouseDraggedFunction = mouseDragged.bind(this);
  let mouseReleasedFunction = mouseReleased.bind(this);
  let mouseWheelFunction = mouseWheel.bind(this);

  const maxCanvas = 800;
  const { img, data } = props;

  const [startPoint, setStartPoint] = useState({
    x: 0,
    y: 0,
  });

  const [currentPoint, setCurrentPoint] = useState({
    x: 0,
    y: 0,
  });

  const [imgData, setImgData] = useState({
    img: null,
    dx: 0,
    dy: 0,
    dw: maxCanvas,
    dh: maxCanvas,
    sx: 0,
    sy: 0,
    sw: 0,
    sh: 0,
  });

  const [isChanged, setIsChanged] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [angle, setAngle] = useState(0);
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    setIsChanged(true);
  }, [props.bbox_index]);

  function preload(p5) {}

  const loadImage = (p5) => {
    setImgLoading(true);
    p5.loadImage(img, (loadedImage) => {
      let sxNew = data[0] * loadedImage.width,
        syNew = data[1] * loadedImage.height,
        swidth = (data[2] - data[0]) * loadedImage.width,
        sHeight = (data[3] - data[1]) * loadedImage.height;

      let dwidth, dHeight;

      if (loadedImage.width > loadedImage.height) {
        dwidth = maxCanvas;
        dHeight = (loadedImage.height / loadedImage.width) * maxCanvas;
      } else {
        dHeight = maxCanvas;
        dwidth = (loadedImage.width / loadedImage.height) * maxCanvas;
      }

      let percentHeight = dHeight / loadedImage.height;
      let percentWidth = dwidth / loadedImage.width;

      p5.resizeCanvas(dwidth, dHeight);

      let tempZoom, xZoom, yZoom, xMargin, yMargin, tenpercent;

      xZoom = loadedImage.width / swidth;
      yZoom = loadedImage.height / sHeight;

      // console.log(xZoom, yZoom);

      if (xZoom < yZoom) {
        // console.log("x zoomed");
        tempZoom = xZoom;
        tenpercent = tempZoom - 1;
        xMargin = ((swidth / tempZoom) * tenpercent) / 2;
        yMargin = dHeight - sHeight * percentHeight * tempZoom + tenpercent / 2;
      } else {
        // console.log("yZoomed");
        tempZoom = yZoom;
        tenpercent = tempZoom - 1;
        yMargin = ((sHeight / tempZoom) * tenpercent) / 2;
        xMargin = dwidth - swidth * percentWidth * tempZoom + tenpercent / 2;
      }
      // console.log(loadedImage);
      // console.log(loadedImage.width / (tempZoom - tenpercent));

      let newWidth = loadedImage.width / (tempZoom - tenpercent);
      let newHeight = loadedImage.height / (tempZoom - tenpercent);

      let newPercentageWidth = dwidth / newWidth;
      let newPercentageHeight = dHeight / newHeight;

      xMargin = dwidth - swidth * newPercentageWidth;
      yMargin = dHeight - sHeight * newPercentageHeight;

      // yMargin = (dHeight * (1 - 1 / (tempZoom - tenpercent))) / 2;

      xMargin /= 2 * newPercentageWidth;
      yMargin /= 2 * newPercentageHeight;

      setZoom(tempZoom - tenpercent);
      // console.log(
      //   "swidth",
      //   swidth,
      //   "tempzoom",
      //   tempZoom,
      //   "tenpercent",
      //   tenpercent,
      //   "swidth / zoom",
      //   swidth / tempZoom
      // );
      // console.log(
      //   xMargin,
      //   yMargin,
      //   "margins",
      //   "dw",
      //   dwidth,
      //   "dh",
      //   dHeight,
      //   "sx",
      //   (sxNew - xMargin) * (tempZoom - tenpercent),
      //   "sy",
      //   (syNew - yMargin) * (tempZoom - tenpercent),
      //   "sw",
      //   loadedImage.width,
      //   "sh",
      //   loadedImage.height
      // );

      setImgData((pre) => {
        return {
          ...pre,
          img: loadedImage,
          dw: dwidth,
          dh: dHeight,
          sx: (sxNew - xMargin) * (tempZoom - tenpercent),
          sy: (syNew - yMargin) * (tempZoom - tenpercent),
          sw: loadedImage.width,
          sh: loadedImage.height,
        };
      });
      setImgLoading(false);
    });
  };

  function setup(p5, canvasParentRef) {
    p5.createCanvas(imgData.dw, imgData.dh).parent(canvasParentRef);
  }

  function mousePressed(p5, e) {
    if (e.target.className !== "p5Canvas") return false;
    let percentHeight = imgData.dh / imgData.img.height;
    let percentWidth = imgData.dw / imgData.img.width;
    setStartPoint((pre) => {
      return {
        x: e.pageX - e.target.offsetLeft,
        y: e.pageY - e.target.offsetTop,
      };
    });
    setCurrentPoint((pre) => {
      return {
        x: (e.pageX - e.target.offsetLeft) / percentWidth,
        y: (e.pageY - e.target.offsetTop) / percentHeight,
      };
    });
  }

  function mouseDragged(p5, e) {
    if (e.target.className !== "p5Canvas") return false;

    let percentHeight = imgData.dh / imgData.img.height;
    let percentWidth = imgData.dw / imgData.img.width;

    let newX = e.pageX - e.target.offsetLeft;
    let newY = e.pageY - e.target.offsetTop;

    let oldX = startPoint.x;
    let oldY = startPoint.y;

    let flag = false;

    if (imgData.sx - newX + oldX < 0) {
      setImgData((pre) => ({
        ...pre,
        sx: 0,
      }));
      flag = true;
    }
    if (imgData.sy - newY + oldY < 0) {
      setImgData((pre) => ({
        ...pre,
        sy: 0,
      }));
      flag = true;
    }

    if (!flag) {
      let finalX = (oldX - newX) / percentWidth / zoom;
      let finalY = (oldY - newY) / percentHeight / zoom;
      setImgData((pre) => ({
        ...pre,
        sx: pre.sx + finalX,
        sy: pre.sy + finalY,
      }));
    }

    setStartPoint({
      x: newX,
      y: newY,
    });
  }

  function mouseReleased(p5, event) {
    // p5.clear();
    // let currentX = currentPoint.x,
    //   currentY = currentPoint.y;
    // setImgData((pre) => {
    //   return {
    //     ...pre,
    //     sx: pre.sx + currentX,
    //     sy: pre.sy + currentY,
    //   };
    // });
    // setCurrentPoint({
    //   x: 0,
    //   y: 0,
    // });
  }

  function mouseWheel(p5, e) {
    if (e.target.className !== "p5Canvas") return false;
    e.preventDefault();

    let tempZoom = zoom - e.delta / 1000;

    let percentHeight = imgData.dh / imgData.img.height;
    let percentWidth = imgData.dw / imgData.img.width;

    let canvasX = e.pageX - e.target.offsetLeft;
    let canvasY = e.pageY - e.target.offsetTop;

    // let imageleftold = canvasX * (imgData.sw / zoom);
    // let imagetopold = canvasY * (imgData.sh / zoom);

    // let imageleftnew = canvasX * (imgData.sw / tempZoom);
    // let imagetopnew = canvasY * (imgData.sh / tempZoom);

    let cpw = (canvasX / imgData.dw) * imgData.sw;
    let cph = (canvasY / imgData.dh) * imgData.sh;

    let leftPercent = (imgData.sx + cpw / zoom) / imgData.sw;
    let topPercent = (imgData.sy + cph / zoom) / imgData.sh;

    let newSx = leftPercent * imgData.sw - cpw / tempZoom;
    let newSy = topPercent * imgData.sh - cph / tempZoom;

    if (tempZoom <= 1) {
      tempZoom = 1;
      setImgData((pre) => {
        return {
          ...pre,
          sx: 0,
          sy: 0,
        };
      });
      setZoom(tempZoom);
      return;
    }

    // setImgData((pre) => {
    //   return {
    //     ...pre,
    //     // dw: pre.dw + event.delta,
    //     // dh: pre.dh + event.delta,
    //     sw: pre.sw * ScaleFactor,
    //     sh: pre.sh * ScaleFactor,
    //   };
    // });

    setImgData((pre) => ({
      ...pre,
      sx: newSx,
      sy: newSy,
    }));

    setZoom(tempZoom);
  }

  function draw(p5) {
    if (isChanged) {
      loadImage(p5);
      setIsChanged(false);
    }
    p5.clear();
    if (imgLoading || imgData.img == null) {
      p5.translate(imgData.dw / 2, imgData.dh / 2);
      p5.background(51);
      p5.strokeWeight(4);
      p5.stroke(255, 204, 100);
      p5.noFill();
      p5.rotate(angle);
      p5.arc(0, 0, 80, 80, 0, p5.PI + p5.QUARTER_PI);
      setAngle((pre) => pre + 0.1);
      return;
    }
    p5.image(
      imgData.img,
      imgData.dx,
      imgData.dy,
      imgData.dw,
      imgData.dh,
      imgData.sx,
      imgData.sy,
      imgData.sw / zoom,
      imgData.sh / zoom
    );

    let percentHeight = imgData.dh / imgData.img.height;
    let percentWidth = imgData.dw / imgData.img.width;
    let displaceX = (imgData.sx / imgData.sw) * imgData.dw;
    let displaceY = (imgData.sy / imgData.sh) * imgData.dh;
    let startingX = data[0] * imgData.img.width * percentWidth - displaceX,
      startingY = data[1] * imgData.img.height * percentHeight - displaceY,
      width =
        data[2] * imgData.img.width * percentWidth -
        data[0] * imgData.img.width * percentWidth,
      height =
        data[3] * imgData.img.height * percentHeight -
        data[1] * imgData.img.height * percentHeight;
    p5.strokeWeight(4);
    p5.stroke(150, 200, 250);
    p5.rect(startingX * zoom, startingY * zoom, width * zoom, height * zoom);
  }

  return (
    <Sketch
      setup={setupFunction}
      preload={preloadFunction}
      draw={drawFunction}
      mousePressed={mousePressedFunction}
      mouseDragged={mouseDraggedFunction}
      mouseReleased={mouseReleasedFunction}
      mouseWheel={mouseWheelFunction}
    />
  );
};

export default Draggable;
