import React, { useEffect, useState } from "react";

import Header from "../../Components/common/Header";
import PanZoom from "../sketch/PanZoom";
import Loader from "../../Components/common/Loader";
import Timer from "../../Components/common/Timer";
import { DatePicker, Select, Radio, message } from "antd";

import { getAuditByDate, sendBackToCV } from "../../API/AuditReview";
import { Logout, ClearClaims } from "../../API/Logout";
import { computeDate } from "../../services";

import "./AuditReview.css";
import "antd/dist/antd.css";

const AuditReview = () => {
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const [cvdata, setCvData] = useState([]);
  const [emaildata, setEmailData] = useState([]);
  const [storedata, setStoreData] = useState([]);
  const [baytypedata, setBayTypeData] = useState([]);
  const [baynumberdata, setBayNumberData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [audit, setAudit] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [store, setStore] = React.useState("");
  const [baytype, setBayType] = React.useState("");
  const [baynumber, setBayNumber] = React.useState("");

  useEffect(() => {}, []);

  const handleSendBackToCV = async (e) => {
    try {
      if (audit !== "" && value !== "" && baynumber !== "") {
        var data = {
          audit_id: audit,
          image_index: baynumber,
          send_to_cv: value === 1 ? true : false,
        };
        sendBackToCV(data)
          .then((res) => {
            // alert("Done");
            message.success("Done");
          })
          .catch((error) => {
            console.log(error.toString());
          });
        /*let url = `${config.apiUrl}/updateauditbyprocessedimage?audit=${audit}&send_back=${value}&index=${baynumber}`;
        await fetch(url, requestOptions)
          .then(handleResponse)
          .then((data) => {
            if (data.message === 1) alert("Done");
            else alert("Error");
          })
          .catch((error) => {
            console.log(error.toString());
          });*/
      } else alert("Please select data.");
    } catch (error) {
      console.log(error.toString());
    }
  };

  async function onChangeDate(date, dateString) {
    setLoading(true);
    setCvData([]);
    setEmailData([]);
    setStoreData([]);
    setBayTypeData([]);
    setBayNumberData([]);
    setStore("");
    setBayType("");
    setBayNumber("");
    getAuditByDate(dateString)
      .then((res) => {
        if (res.error) {
          if (res.errorCode && res.errorCode === "NO_ACCESS_TOKEN") {
            Logout();
          }
        } else {
          if (res.data && Object.keys(res.data).length > 0) {
            var arrData = [];
            var arrEmail = [];
            var arrTemp = [];
            var arrStore = [];
            var arrBayType = [];
            var arrBayNumber = [];
            for (var i = 0; i < Object.keys(res.data).length; i++) {
              if (arrTemp.indexOf(res.data[i].user) === -1)
                arrEmail.push({
                  label: res.data[i].user,
                  value: res.data[i].user,
                });
              arrTemp.push(res.data[i].user);
              arrStore.push({
                label:
                  res.data[i].store_name +
                  " (" +
                  computeDate(
                    res.data[i].dateSubmission
                      ? res.data[i].dateSubmission
                      : res.data[i].date
                  ) +
                  ")",
                value: res.data[i]._id + "@@@" + res.data[i].store,
              });
            }
            setCvData(res.data);
            setEmailData(arrEmail);
            setStoreData(arrStore);
            setBayTypeData([]);
            setBayNumberData([]);
          } else {
            setCvData([]);
            setEmailData([]);
            setStoreData([]);
            setBayTypeData([]);
            setBayNumberData([]);
          }
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }

  async function onChangeStore(value) {
    setLoading(true);
    setBayTypeData([]);
    setBayType("");
    setBayNumber("");
    var arrBayType = [];
    var arrTemp = [];
    setAudit(value.split("@@@")[0]);
    for (var i = 0; i < Object.keys(cvdata).length; i++) {
      console.log(cvdata[i]);
      if (cvdata[i]._id === value.split("@@@")[0]) {
        for (var j = 0; j < cvdata[i].processed_bay_type.length; j++) {
          if (cvdata[i].store === value.split("@@@")[1]) {
            if (arrTemp.indexOf(cvdata[i].processed_bay_type[j]) === -1)
              arrBayType.push({
                label: cvdata[i].processed_bay_type[j],
                value: cvdata[i].processed_bay_type[j],
              });
            arrTemp.push(cvdata[i].processed_bay_type[j]);
          }
        }
      }
    }
    setStore(value);
    setBayTypeData(arrBayType);
    setLoading(false);
  }

  async function onChangeBayType(value) {
    setLoading(true);
    setBayNumberData([]);
    setBayNumber("");
    var arrBayNumber = [];
    for (var i = 0; i < Object.keys(cvdata).length; i++) {
      if (cvdata[i]._id === audit) {
        for (var j = 0; j < cvdata[i].processed_bay_type.length; j++) {
          if (
            cvdata[i].store === store.split("@@@")[1] &&
            cvdata[i].processed_bay_type[j] === value
          ) {
            arrBayNumber.push({
              label: cvdata[i].processed_bay_num[j],
              value: j,
            });
          }
        }
      }
    }
    setBayType(value);
    setBayNumberData(arrBayNumber);
    setLoading(false);
  }

  async function onChangeBayNumber(value) {
    setBayNumber(value);
  }

  const onChange = (e) => {
    setValue(e.target.value);
  };

  /*const sendData = () => {
    let resBody = {
      price_scan_id: imgData.price_scan_id,
      image_index: imgData.image_index,
      store_id: imgData.store_id,
      store_name: imgData.store_name,
      manufacturer_id: imgData.manufacturer_id,
      product_id: [],
      price: [],
      price_type: [],
    };
    formData.forEach((data, index) => {
      if (data.price !== "" && data.price !== 0) {
        resBody.product_id.push(data.productList);
        resBody.price.push(data.price);
        resBody.price_type.push("Regular");
      }
      if (data.discountedPrice !== "" && data.discountedPrice !== 0) {
        resBody.product_id.push(data.productList);
        resBody.price.push(data.discountedPrice);
        resBody.price_type.push(data.discountedType);
      }
    });
    setLoading(true);
    updatePriceVerification(resBody).then((res) => getNextImg());
  };

  const skip = () => {
    let userType = localStorage.getItem("role");
    let resBody = {
      ...imgData,
    };
    if (userType === "ordinary") {
      resBody.escalate = true;
    } else {
      resBody.skip = true;
    }

    setLoading(true);
    updatePriceVerification(resBody).then((res) => {
      getNextImg();
    });
  };

  const addProdFn = () => {
    setFormData((pre) => {
      if (pre.length > 0) {
        return [...pre, { ...formTemplate }];
      } else {
        return [{ ...formTemplate }];
      }
    });
  };

  const deleteArray = (index) => {
    setFormData((pre) => {
      pre.splice(index, 1);
      return [...pre];
    });
  };*/

  return (
    <div>
      {loading && <Loader />}
      <Header />
      <div style={{ padding: 20 }}>
        <div className="row" style={{ fontSize: 14 }}>
          <div className="col-sm-7" style={{ padding: 10 }}>
            <div className="row" style={{ padding: 10 }}>
              <strong className="col-sm-2" style={{ marginTop: 7 }}>
                Date:{" "}
              </strong>
              <div className="col-sm-10">
                <DatePicker onChange={onChangeDate} />
              </div>
            </div>

            <div className="row" style={{ padding: 10 }}>
              <strong className="col-sm-2" style={{ marginTop: 7 }}>
                Store:{" "}
              </strong>
              <div className="col-sm-10">
                <Select
                  //showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a store"
                  optionFilterProp="children"
                  onChange={onChangeStore}
                  options={storedata}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={store}
                ></Select>
              </div>
            </div>
            <div className="row" style={{ padding: 10 }}>
              <strong className="col-sm-2" style={{ marginTop: 7 }}>
                Bay type:{" "}
              </strong>
              <div className="col-sm-10">
                <Select
                  //showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a bay type"
                  optionFilterProp="children"
                  onChange={onChangeBayType}
                  options={baytypedata}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={baytype}
                ></Select>
              </div>
            </div>
            <div className="row" style={{ padding: 10 }}>
              <strong className="col-sm-2" style={{ marginTop: 7 }}>
                Bay number:{" "}
              </strong>
              <div className="col-sm-10">
                <Select
                  //showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a bay number"
                  optionFilterProp="children"
                  onChange={onChangeBayNumber}
                  options={baynumberdata}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={baynumber}
                ></Select>
              </div>
            </div>
            <div className="row" style={{ padding: 10 }}>
              <strong className="col-sm-2" style={{}}>
                Send Back to CV:{" "}
              </strong>
              <div className="col-sm-10">
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={1}>YES</Radio>
                  <Radio value={0}>NO</Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <button
              style={{
                fontSize: 14,
                backgroundColor: "#56B356",
                borderColor: "#56B356",
                marginTop: 20,
              }}
              className="btn btn-primary"
              onClick={handleSendBackToCV}
            >
              Process
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditReview;
