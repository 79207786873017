import { renewToken } from "./RefreshToken";

export const errorHandler = async (err, fn, args = {}) => {
  if (err.response.status === 401) {
    let tokenRefreshed = false;
    for (let index = 0; index < 3; index++) {
      tokenRefreshed = await renewToken();
      if (tokenRefreshed) break;
    }
    if (tokenRefreshed) {
      return fn(args);
    } else {
      return {
        error: true,
        message: "Error Occured while fetching data",
      };
    }
  } else if (err.response.status === 404) {
    return {
      error: true,
      message: "No new image found!",
    };
  } else {
    return {
      error: true,
      message: `${err.response.status} : ${err.response.statusText}`,
    };
  }
};

export const commonRes = (err, data, errorCode = "") => {
  let res = {
    error: false,
    message: "",
    data: {},
  };
  if (err) {
    res.error = true;
    res.message = data;
  } else {
    res.error = false;
    res.data = data;
  }

  if (errorCode.length > 0) {
    res.errorCode = errorCode;
  }

  return res;
};
